import React, { useEffect, useState } from "react";
import Close from "@assets/Buy/Close.svg";
import { IRequestDTO } from "@components/Chat/types/IRequest";
import api from "@services/client/api";
import ScreenshotUpload from "./upload";
import { CompleteOrderData } from "@services/server/(order)";

interface BoosterPopupProps {
  token: string;
  row_order: IRequestDTO;
  onClose: () => void;
  onSubmit: (token: string, data: CompleteOrderData) => void;
}

export interface Screenshot {
  id: string;
  file: File | string;
  link?: string;
}

const FinalizeServiceBooster: React.FC<BoosterPopupProps> = ({
  token,
  row_order,
  onClose,
  onSubmit,
}) => {
  const [screenshots, setScreenshots] = useState<Screenshot[]>([]);
  const [selectedImage, setSelectedImage] = useState<Screenshot | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [order, setOrder] = useState<IRequestDTO | null>(null);

  useEffect(() => {
    const fetchOrder = async () => {
      if (!token) return;
      try {
        const response = await api.get(`/order/${row_order.id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setOrder(response.data);
      } catch (error) {
        console.error("Erro ao buscar a ordem:", error);
      }
    };

    fetchOrder();
  }, [row_order, token]);

  const updateScreenshots = (
    action: "add" | "remove",
    payload: Screenshot | number
  ) => {
    setScreenshots((prevScreenshots) => {
      switch (action) {
        case "add":
          return [...prevScreenshots, payload as Screenshot];
        case "remove":
          return prevScreenshots.filter((_, index) => index !== (payload as number));
        default:
          return prevScreenshots;
      }
    });
  };

  const handleImageClick = (index: number) => {
    if (screenshots[index]) {
      setSelectedImage(screenshots[index]);
    }
  };

  const handleAddImageClick = (index: number) => {
    document.getElementById(`file-input-${index}`)?.click();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50 transition-opacity duration-300">
      <div className="bg-blue-50 px-8 pb-6 pt-4 flex flex-col items-center justify-center gap-6 rounded-xl border-2 border-gray-600 border-opacity-30 shadow-2xl w-3/4 transition-all duration-300">
        <div className="w-full flex justify-between items-center">
          <div className="text-left">
            <h2 className="text-gray-500 text-md font-bold">
              Número do Serviço: #124
            </h2>
          </div>
          <button onClick={onClose}>
            <img src={Close} alt="Close" width={20} height={20} />
          </button>
        </div>

     <div className="w-full h-full">
     <div className="relative w-full h-full flex flex-row justify-center items-center gap-4">
          <ScreenshotUpload
            order={order}
            token={token}
            screenshots={screenshots}
            setScreenshots={setScreenshots}
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            handleImageClick={handleImageClick}
            handleAddImageClick={handleAddImageClick}
            loading={loading}
            setLoading={setLoading}
            updateScreenshots={updateScreenshots}
          />
        </div>
        <div className="w-full flex items-center justify-center mt-4">
            <button
              className={`bg-indigo-600 w-full rounded-md text-md px-4 py-2 shadow-inner transition-all duration-300 hover:bg-primary-500/40 text-white font-medium ${
                order?.status !== "booster_in_progress" ? "opacity-50 cursor-not-allowed" : ""
              }`}
              onClick={() => onSubmit(token, {operation_id: order?.operation ? order?.operation?.id : "", order_id: order ? order.id : ""})}
              disabled={order?.status !== "booster_in_progress"}
            >
              Concluir
            </button>
          </div>
     </div>
      </div>
    </div>
  );
};

export default FinalizeServiceBooster;
