import { IUser } from "@utils/types";
import React from "react";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
} from "@material-tailwind/react";

interface BoosterActionsProps {
  user: IUser;
  status: string;
  handleComplete: () => void;
  handleAbandon: () => void;
  translate: (key: string) => string;
}

const BoosterActions: React.FC<BoosterActionsProps> = ({
  user,
  status,
  handleComplete,
  handleAbandon,
  translate,
}) => {
  if (user.type_permission !== "BOOSTER" || status !== "booster_in_progress") {
    return null;
  }

  return (
    <div className="flex flex-row items-center justify-center gap-3">
      <button
        className="bg-primary-350 rounded-full outline-none text-center p-2 px-5 text-sm text-gray-500 font-semibold hover:opacity-90 active:bg-[#56BD79]"
        onClick={handleComplete}
      >
        {translate("chat.complete")}
      </button>

      <div className="flex">
        <Menu placement="bottom-end">
          <MenuHandler>
            <button
              className="bg-blue-20 rounded-full outline-none text-center py-3 px-5 text-sm text-gray-500 font-semibold hover:opacity-70"
            >
              <svg
                width="27"
                height="8"
                viewBox="0 0 27 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22.964 4H23.036"
                  stroke="#FFFFFF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M13.964 4H14.036"
                  stroke="#FFFFFF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.964 4H4.036"
                  stroke="#FFFFFF"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </MenuHandler>
          <MenuList className="bg-blue-50 border-gray-900 border-2 flex items-center justifty-center">
            <MenuItem className="bg-blue-20 p-2 m-0 flex justify-center items-center text-center hover:bg-indigo-600 text-xs whitespace-nowrap font-semibold text-white" onClick={handleAbandon}>Abandonar o serviço</MenuItem>
          </MenuList>
        </Menu>
      </div>
    </div>
  );
};

export default BoosterActions;
