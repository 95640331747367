import api from "@services/client/api";
import { AxiosRequestConfig } from "axios";
import { CheckoutPriceResult } from "types/checkout/ICheckoutPriceResult";
import { ICreateCheckout } from "types/checkout/ICreateCheckout";
import { IResponseCheckout } from "types/checkout/IResponseCheckout";
import { ICheckoutItem } from "types/checkout_item/ICheckoutItem";
import { IResponseCheckoutItem } from "types/checkout_item/IResponseItem";


export function getDefaultAxiosConfig(token: string): AxiosRequestConfig {
  if (!token) {
    throw new Error("Token de autenticação não fornecido.");
  }

  return {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
}

export async function createCheckout(checkoutData: ICreateCheckout, token: string): Promise<IResponseCheckout> {
  try {
    if (!token) {
      throw new Error("Token de autenticação não fornecido.");
    }
    const config: AxiosRequestConfig = getDefaultAxiosConfig(token);
    const response = await api.post<IResponseCheckout>('/checkout', checkoutData, config);
    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data.message || "Não foi possível obter o checkout");
  }
};

export async function updatePaymentMethods(checkoutId: string, paymentMethod: string, token: string): Promise<any> {
  try {
    if (!token) {
      throw new Error("Token de autenticação não fornecido.");
    }
    if (!checkoutId || !paymentMethod) {
      throw new Error("Você precisa fornecer todos os dados.");
    }

    const requestBody = {
      checkout_id: checkoutId,
      payment_method: paymentMethod
    };

    const config: AxiosRequestConfig = getDefaultAxiosConfig(token);
    await api.patch('/checkout', requestBody, config);
  } catch (error: any) {
    throw new Error(error.response.data.message || "Erro ao atualizar os métodos de pagamento.");
  }
}

export async function updateCurrency(checkoutId: string, currency: string, token: string): Promise<string | any> {
  try {
    if (!token) {
      throw new Error("Token de autenticação não fornecido.");
    }
    if (!checkoutId || !currency) {
      throw new Error("Você precisa fornecer todos os dados.");
    }

    const requestBody = {
      checkout_id: checkoutId,
      currency: currency
    };

    const config: AxiosRequestConfig = getDefaultAxiosConfig(token);
    let currencyResponse = await api.patch('/checkout/currency', requestBody, config);
    return currencyResponse;
  } catch (error: any) {
    // throw new Error(error.response.data.message || "Erro ao atualizar a moeda.");
  }
}


export async function updateManiacoins(checkoutId: string, status: boolean, token: string): Promise<string | any> {
  try {
    if (!token) {
      throw new Error("Token de autenticação não fornecido.");
    }
    if (!checkoutId) {
      throw new Error("Você precisa fornecer todos os dados.");
    }

    const requestBody = {
      checkout_id: checkoutId,
      status: status
    };

    const config: AxiosRequestConfig = getDefaultAxiosConfig(token);
    let currencyResponse = await api.patch('/checkout/mania', requestBody, config);
    return currencyResponse;
  } catch (error: any) {
    // throw new Error(error.response.data.message || "Erro ao atualizar seus maniacoins.");
  }
}

export async function updateCoupon(checkoutId: string, coupon: string, action: string, token: string): Promise<IResponseCheckoutItem[] | any> {
  try {
    if (!token) {
      throw new Error("Token de autenticação não fornecido.");
    }
    if (!checkoutId) {
      throw new Error("Você precisa fornecer todos os dados.");
    }

    const requestBody = {
      checkout_id: checkoutId,
      coupon: coupon,
      action: action
    };

    const config: AxiosRequestConfig = getDefaultAxiosConfig(token);
    let res = await api.patch('/checkout/coupon', requestBody, config);
    return res.data;
  } catch (error: any) {
    throw new Error(error.response.data.error);
  }
}


export async function createCheckoutItem(data: ICheckoutItem, token: string): Promise<IResponseCheckoutItem | any> {
  try {
    if (!token) {
      throw new Error("Token de autenticação não fornecido.");
    }
    if (!data) {
      throw new Error("Você precisa fornecer todos os dados.");
    }

    const requestBody = {
      client_id: data.client_id,
      checkout_id: data.checkout_id,
      item_id: data.item_id,
      type: data.type,
      name: data.name,
      isPromotional: data.isPromotional,
      subtitle: data.subtitle,
      tags: data.tags,
      price: data.price,
      image: data.image,
      description: data.description,
    };

    const config: AxiosRequestConfig = getDefaultAxiosConfig(token);
    let item = await api.post('/checkout-item', requestBody, config);
    return item.data;
  } catch (error: any) {
    // throw new Error(error.response.data.message || "Erro ao adicionar o item ao checkout.");
  }
}

export async function deleteCheckoutItem(itemId: string, token: string): Promise<void> {
  try {
    if (!token) {
      throw new Error("Token de autenticação não fornecido.");
    }
    if (!itemId) {
      throw new Error("Você precisa fornecer o ID do item que quer deletar.");
    }

    const config: AxiosRequestConfig = getDefaultAxiosConfig(token);
    await api.delete(`/checkout-item/${itemId}`, config);
  } catch (error: any) {
    throw new Error(error.response.data.message || "Erro ao excluir o item do checkout.");
  }
}

export async function validateCheckoutItems(checkoutId: string, token: string): Promise<IResponseCheckoutItem[] | any> {
  try {
    if (!token) {
      throw new Error("Token de autenticação não fornecido.");
    }
    if (!checkoutId) {
      throw new Error("Você precisa fornecer o ID do checkout.");
    }

    const config: AxiosRequestConfig = getDefaultAxiosConfig(token);
    let response = await api.patch(`/checkout-item/${checkoutId}`, {}, config);
    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data.message || "Erro ao validar os itens do checkout.");
  }
}

export async function getCheckoutById(checkoutId: string, token: string): Promise<IResponseCheckout> {
  try {
    if (!token) {
      throw new Error("Token de autenticação não fornecido.");
    }
    if (!checkoutId) {
      throw new Error("Você precisa fornecer o ID do checkout.");
    }

    const config: AxiosRequestConfig = getDefaultAxiosConfig(token);
    let response = await api.get(`/checkout/${checkoutId}`, config);
    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data.message || "Erro ao obter os dados do checkout.");
  }
}

export async function getCheckoutPrice(checkoutId: string, token: string): Promise<CheckoutPriceResult | any> {
  try {
    if (!token) {
      throw new Error("Token de autenticação não fornecido.");
    }
    if (!checkoutId) {
      throw new Error("Você precisa fornecer o ID do checkout.");
    }

    const config: AxiosRequestConfig = getDefaultAxiosConfig(token);
    let response = await api.get(`/checkout/price/${checkoutId}`, config);
    return response.data;
  } catch (error: any) {
    // throw new Error(error.response.data.message || "Erro ao obter os dados do checkout.");
  }
}
