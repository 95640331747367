import React, { useState } from 'react';
import {
  FaTasks,
  FaCheckCircle,
  FaCopy,
  FaUndoAlt,
  FaInfoCircle,
  FaMoneyBillWave,
  FaTimesCircle,
} from 'react-icons/fa';
import DropdownUsers from './(subitems)/boosters';
import { IRequestDTO } from '@components/Chat/types/IRequest';
import { useAuth } from '@context/AuthContext';

interface DropdownItem {
  label: string;
  icon: React.ReactNode;
  action?: string;
  subItems?: DropdownItem[];
  enabledStatuses?: string[]; 
}

interface DropdownProps {
  row: IRequestDTO; 
  onRowAction: (action: string, row: IRequestDTO) => void;
  moveOrderToTab: (orderId: string, newTab: string) => void;
}

const Dropdown: React.FC<DropdownProps> = ({ row, onRowAction, moveOrderToTab }) => {
  const { token } = useAuth();
  const [activeSubMenu, setActiveSubMenu] = useState<number | null>(null);

  const toggleSubMenu = (index: number) => {
    setActiveSubMenu(prev => (prev === index ? null : index));
  };

  const closeDropdown = () => {
    setActiveSubMenu(null);
  };

  const currentStatus = row.status;

  const menuItems: DropdownItem[] = [
    {
      label: 'Atribuir Serviço',
      icon: <FaTasks />,
      action: 'assign_service',
      enabledStatuses: ['available', 'booster_in_progress', 'booster_abandoned'], 
      subItems: [],
    },
    {
      label: 'Copiar ID',
      icon: <FaCopy />,
      action: 'copy_id',
      enabledStatuses: [], 
    },
    {
      label: 'Aprovar Pedido',
      icon: <FaCheckCircle />,
      action: 'approve_order',
      enabledStatuses: ['booster_finished'],
    },
    {
      label: 'Pagar Serviço',
      icon: <FaMoneyBillWave />,
      action: 'pay_service',
      enabledStatuses: ['approved'],
    },
    {
      label: 'Reembolsar',
      icon: <FaUndoAlt />,
      action: 'refund',
      enabledStatuses: ['available', 'approved', 'completed', 'booster_in_progress', 'booster_abandoned', 'booster_finished'], 
    },
    {
      label: 'Voltar aos Disponíveis',
      icon: <FaUndoAlt />,
      action: 'return_to_available',
      enabledStatuses: ['booster_in_progress', 'booster_abandoned', 'booster_finished'], 
    },
    {
      label: 'Informações',
      icon: <FaInfoCircle />,
      action: 'view_info',
      enabledStatuses: [],
    },
    {
      label: 'Cancelar Serviço',
      icon: <FaTimesCircle />,
      action: 'cancel_service',
      enabledStatuses: [], 
    },
  ];

  return (
    <div className="relative rounded-lg shadow-lg w-64 py-4 px-2">
      <ul className="space-y-2 text-sm text-white">
        {menuItems.map((item, index) => {
            const isEnabled = item.enabledStatuses?.length === 0 || item.enabledStatuses?.includes(currentStatus);

          return (
            <li key={index} className="relative group">
              {item.subItems ? (
                <>
                  <button
                    onClick={() => toggleSubMenu(index)}
                    disabled={!isEnabled}
                    className={`flex items-center justify-between w-full px-4 py-2 text-left bg-blue-20 hover:bg-indigo-600 rounded-lg transition-all duration-200 ease-in-out ${!isEnabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                  >
                    <div className="flex items-center space-x-3">
                      <span className="text-indigo-600 group-hover:text-white transition-colors duration-200 ease-in-out">{item.icon}</span>
                      <span className="ml-3 font-medium text-gray-200 group-hover:text-white transition-colors duration-200 ease-in-out">{item.label}</span>
                    </div>
                    <svg
                      className="w-4 h-4 text-indigo-600 group-hover:text-white transition-colors duration-200 ease-in-out"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 6 10"
                      aria-hidden="true"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M1 9l4-4-4-4"
                      />
                    </svg>
                  </button>
                  {activeSubMenu === index && isEnabled && (
                    <div className="absolute right-[130%] top-0 z-10 w-48 rounded-lg">
                      <DropdownUsers game={row.game?.value || ""} token={token || ""} orderId={row.id} onAssignBooster={closeDropdown} moveOrderToTab={moveOrderToTab}/>
                    </div>
                  )}
                </>
              ) : (
                <button
                  onClick={() => onRowAction(item.action || '', row)}
                  disabled={!isEnabled} 
                  className={`flex items-center w-full px-4 py-2 bg-blue-20 hover:bg-indigo-600 rounded-lg transition-all duration-200 ease-in-out group ${!isEnabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  <span className="text-indigo-600 group-hover:text-white transition-colors duration-200 ease-in-out">
                    {item.icon}
                  </span>
                  <span className="ml-3 font-medium text-gray-200 group-hover:text-white transition-colors duration-200 ease-in-out">
                    {item.label}
                  </span>
                </button>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Dropdown;
