import ChatContainer from "./(message_container)";
import ProfileImage from "./ProfileDetails";
import { IRequestDTO, IResponseAdditionalDTO } from "./types/IRequest";
import Feedback from "@assets/Table/Feedback.svg";
import MiniBag from "@assets/Table/MiniBag.svg";
import Client from "@assets/Table/Client.svg";
import { useTranslation } from "react-i18next";
import { format } from "currency-formatter";
import ServiceInfo from "./(service_details)";
import RenderPreferences from "./(service_details)/(preferences)";
import ClientInfo from "./(service_details)/(client)";
import GorjetaComponent from "./(tip)";
import BoosterActions from "./(actions)/(booster)";

import AbandonServicePopup from "./(actions)/(booster)/(popup)/(abandon_service)";
import { useState } from "react";
import { AbandonServiceData, CompleteOrderData, sendAbandonRequest, sendCompleteRequest } from "@services/server/(order)";
import { toast } from "react-toastify";
import FinalizeServiceBooster from "./(actions)/(booster)/(popup)/(finalize_service)";


type Props = {
  request: IRequestDTO;
  user: any;
  token: string;
};

const RequestDetailsPage = ({ request, user, token }: Props) => {
  const { t } = useTranslation();
  const [popupsState, setPopupsState] = useState<{ [key: string]: boolean }>({});

  const openPopup = (popupName: string) => {
    setPopupsState((prevState) => ({
      ...prevState,
      [popupName]: true,
    }));
  };

  const closePopup = (popupName: string) => {
    setPopupsState((prevState) => ({
      ...prevState,
      [popupName]: false,
    }));
  };


  const handleComplete = async (
    token: string,
    data: CompleteOrderData
  ) => {
    try {
      const response = await sendCompleteRequest(token, data);
      toast.success(response.message);
      closePopup("completeOrder");
      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    } catch (error: any) {
      toast.error(`${error?.response?.data?.message || error.message}`);
    }
  };

  const handleAbandon = async (token: string, data: AbandonServiceData) => {
    try {
      const response = await sendAbandonRequest(token, data)
      toast.success(response.message);
      closePopup("abandonService");

      setTimeout(() => {
        window.location.href = "/";
      }, 1000);
    } catch (error: any) {
      toast.error(`${error?.response?.data?.message || error.message}`);
    }
  };

  const translate = (key: string) => {
    const translations: Record<string, string> = {
      "chat.complete": "Concluir",
    };
    return translations[key] || key;
  };

  return (
    <div className="w-full">
      <h1 className="text-3xl text-gray-200 font-semibold mb-4">Pedido: <span className="text-indigo-600">#{request.relative_id}</span></h1>
      <div className="bg-white-100 dark:bg-blue-50 rounded-2xl p-4 border border-secondary-500 grid sm:flex items-center justify-between">
        <div className="flex w-full items-center">
          {request && user && token && (
            <ProfileImage order={request} user={user} token={token} />
          )}
        </div>
        <BoosterActions
          user={user}
          status={request.status}
          handleComplete={() => openPopup("finalizeService")}
          handleAbandon={() => openPopup("abandonService")}
          translate={translate}
        />
      </div>

      <div className="h-full w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        <div className="col-span-2 transition-colors duration-1000 bg-white-100 dark:bg-blue-50 rounded-2xl p-4 border border-secondary-500 mt-4">
          <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base flex items-center gap-2">
            <img src={Feedback} alt={Feedback} />
            Chat
          </h2>
          <ChatContainer user={user} token={token} order={request} />
        </div>

        <div className="col-span-1 transition-colors duration-1000 bg-white-100 dark:bg-blue-50 rounded-2xl p-4 border border-secondary-500 mt-4">
          <div className="flex items-center justify-between">
            <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base flex items-center gap-2">
              <img src={MiniBag} alt={MiniBag} />
              {t("chat.serviceDetails")}
            </h2>

            {(() => {
              const currentLP = request.preferences?.find(preference => preference.type === "lp");

              if (currentLP) {
                return (
                  <span className="text-gray-100 font-semibold text-sm flex flex-row gap-2">
                    {currentLP.label}
                    <span className="text-gray-500">
                      {currentLP.option.value}
                    </span>
                  </span>
                );
              }

              return null;
            })()}


          </div>
          <div className="transition-colors duration-1000 bg-transparent rounded-2xl mt-4">
            <ServiceInfo request={request} />
            <GorjetaComponent request={request} user={user} />
          </div>
        </div>
      </div>

      {popupsState["abandonService"] && (
        <AbandonServicePopup
          token={token}
          order={request}
          onClose={() => closePopup("abandonService")}
          onAbandon={handleAbandon} />
      )}
      {popupsState["finalizeService"] && (
        <FinalizeServiceBooster
          token={token}
          row_order={request}
          onClose={() => closePopup("finalizeService")}
          onSubmit={handleComplete}
        />
      )}

      {/* Informações adicionais */}
      <div className="transition-colors duration-1000 bg-white-100 dark:bg-blue-50 rounded-2xl flex flex-col items-start w-full justify-center p-4 border border-secondary-500 mt-4">
        <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base flex items-center gap-2">
          <img src={Client} alt={Client} />
          {t("chat.additionalInfo")}
        </h2>

        {/* Informações Serviço */}
        <div className="transition-colors duration-1000 bg-white-100 dark:bg-blue-20 w-full rounded-2xl p-4 mt-4">
          <div className="grid sm:flex items-start justify-between gap-6">
            <div className="flex flex-col gap-2 items-start justify-center">
              <h2 className="text-gray-100 dark:text-gray-500 font-semibold text-sm">
                {t("chat.game")}:
              </h2>
              <div className='flex items-center justify-center w-full flex-row pr-2 py-0.5 rounded-md'>
                <img src={request.game?.image} alt="request" className="flex items-center justify-center rounded-md h-10 w-10 p-2 object-contain" />
                <span className="text-md">
                  {request.game?.label}
                </span>{" "}
              </div>
            </div>
            <div className="flex flex-col gap-2 items-start justify-center">
              <h2 className="text-gray-100 dark:text-gray-500 font-semibold text-sm">
                Serviço:
              </h2>
              <div className='flex items-center justify-center w-full flex-row pr-2 py-0.5 rounded-md'>
                <span className="text-md">
                  {request.service?.label}
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-2 items-start justify-center">
              <h2 className="text-gray-100 dark:text-gray-500 font-semibold text-sm">
                {t("chat.server")}:
              </h2>
              <div className='flex items-center justify-center w-full flex-row'>
                <span className="text-md">
                  {request.server?.label} - {request.server?.value}
                </span>{" "}
              </div>
            </div>
            <RenderPreferences
              preferences={request.preferences || []}
              ignoredTypes={["lp", "lp_additional_required", "current_required", "desired_required"]}
            />
            <div className="flex flex-col items-center justify-center gap-2">
              <h2 className="text-gray-100 dark:text-gray-500 font-semibold text-sm">
                {t("chat.price")}:
              </h2>
              <span className="text-gray-100 dark:text-gray-500 text-md">
                <span className="text-primary-200 font-semibold">R$</span>
                {format(Number(request.price), { code: "BRL" }).slice(2)}
              </span>
            </div>
          </div>
        </div>

        {/* Informações Adicionais */}
        <div className="transition-colors duration-1000 bg-white-100 dark:bg-blue-20 rounded-2xl p-4 mt-4 flex flex-col min-w-full">
          <h2 className="text-gray-100 font-semibold text-sm">
            {t("chat.additionalServices")}
          </h2>
          <div className="w-full flex flex-wrap items-center gap-2 mt-1">
            {(request.optionals && request.optionals.length > 0) ? (
              request.optionals.map((optional: IResponseAdditionalDTO, index) => (
                <h3
                  key={index}
                  className="bg-gray-100 text-xs py-2 px-4 rounded-full"
                >
                  {optional.label}
                </h3>
              ))
            ) : (
              <p className="text-gray-500 text-sm">Esse pedido não possui adicionais.</p>
            )}
          </div>
        </div>

        {/* Informações Histórico */}
        {/* <div className="transition-colors duration-1000 bg-white-100 dark:bg-blue-20 rounded-2xl p-4 mt-4">
          <h2 className="text-gray-100 font-semibold text-sm">
            {t("chat.history")}{" "}
          </h2>
          <div className="historic w-full mt-4">
           {user && token && (
              <MatchHistory
                orderId={serviceRequest.id}
                matchCount={5}
                token={token}
              />
            )} 
          </div>
        </div> */}

        <ClientInfo user={user} request={request} />

      </div>
    </div>
  )
};

export default RequestDetailsPage;
