import React, { useEffect, useState } from "react";
import { Booster, IOperation, IRequestDTO } from "@components/Chat/types/IRequest";
import Close from "@assets/Buy/Close.svg";
import { useTranslation } from "react-i18next";
import api from "@services/client/api";
import { useAuth } from "@context/AuthContext";
import { motion } from "framer-motion";
import { QRCode } from "react-qrcode-logo";

type PayPopupProps = {
    row_order: IRequestDTO;
    onClose: () => void;
    moveOrderToTab: (orderId: string, newTab: string) => void;
};


type BoosterProps = {
    booster: Booster;
    operation: IOperation;
    amount: number;
};


const BoosterDetails: React.FC<BoosterProps> = ({ operation, booster, amount }) => {
    const [isCopied, setIsCopied] = useState(false);
    const { t } = useTranslation();

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(booster.payment_key);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    const getStatusClass = () => {
        switch (operation.payment_status) {
            case 'paid':
                return 'text-white text-xs p-1.5 font-semibold bg-gradient-to-r from-green-500 via-green-600 to-green-700 rounded-md';
            case 'reject':
                return 'text-white text-xs p-1.5 font-semibold bg-gradient-to-r from-red-500 via-red-600 to-red-700 rounded-md';
            case 'pending':
                return 'text-black text-xs p-1.5 font-semibold bg-gradient-to-r from-yellow-300 via-yellow-400 to-yellow-500 rounded-md';
            default:
                return '';
        }
    };

    const getStatusLabel = () => {
        switch (operation.payment_status) {
            case 'paid':
                return 'Pago';
            case 'reject':
                return 'Rejeitado';
            case 'pending':
                return 'Aguardando Pagamento';
            default:
                return '';
        }
    };

    return (
        <div
            className={`relative w-full h-full gap-6 flex flex-row items-center justify-center rounded-xl p-6 border-2 ${operation.current ? 'border-indigo-600' : 'border-gray-900'
                } shadow-lg`}
        >



          <div className="w-full flex flex-col">
          <div className="flex items-center gap-4">
                <div className="relative w-16 h-16 border-2 border-indigo-800 rounded-full">
                    <img
                        src={booster.champion.image}
                        alt={booster.champion.name}
                        className="w-full h-full object-cover rounded-full"
                    />
                </div>
                <div className="flex flex-row gap-2 items-center justify-center">
    
                    <h4 className="text-white font-semibold text-xl">
                        {booster?.champion.name}
                    </h4>
                    {operation.current && (
                <span className=" text-white text-xs p-1.5 font-semibold bg-gradient-to-r from-indigo-500 via-blue-600 to-indigo-700 rounded-full">
                    Current
                </span>
            )}
              <span className={getStatusClass()}>
                    {getStatusLabel()}
                </span>
                </div>
            </div>

            <div className="mt-6">
                <div className="flex items-center justify-between mb-4">
                    <p className="text-sm text-gray-300">{booster.services_quantity}</p>
                    <p className="text-sm text-gray-300">{booster.level_remain}</p>
                </div>
                <div className="h-2 bg-gray-700 rounded-full">
                    <div
                        className="h-full bg-gradient-to-r from-indigo-500 via-blue-600 to-indigo-700 rounded-full"
                        style={{
                            width: `${booster?.services_quantity && booster?.level_remain
                                ? (booster.services_quantity / booster.level_remain) * 100
                                : 0
                                }%`,
                        }}
                    ></div>

                </div>
                <div className="flex items-center justify-between mt-6">
                    <p className="text-gray-600 font-semibold text-xs">
                        {t('client.current')}: <span className="text-gray-200">{booster.booster_level}</span>
                    </p>
                    <p className="text-gray-600 font-semibold text-xs">
                        {t('client.next')}: <span className="text-gray-200">{booster.booster_next_level}</span>
                    </p>
                </div>
            </div>

            <div className="m-4 h-[2px] w-full mx-auto bg-gray-900"></div>

            {/* Informações adicionais */}
            <div className="mt-4">
                <div className="w-full grid grid-cols-2 gap-4">
                    <div>
                        <p className="text-gray-400 text-sm font-semibold">Comissão:</p>
                        <p className="text-white text-sm font-medium">
                            {booster.gain_percentage}%
                        </p>
                    </div>
                    <div onClick={handleCopy}>
                        <p className="text-gray-400 text-sm font-semibold">Pix:</p>
                        <p className="text-white text-sm font-medium whitespace-nowrap w-26 overflow-hidden cursor-pointer text-ellipsis flex">
                            {booster.payment_key}
                        </p>
                    </div>
                    <div>
                        <p className="text-gray-400 text-sm font-semibold">Status:</p>
                        <p
                            className={`text-sm font-medium ${operation.type === 'completed'
                                    ? 'text-green-400'
                                    : operation.type === 'abandoned'
                                        ? 'text-red-400'
                                        : operation.type === 'replaced'
                                            ? 'text-yellow-400'
                                            : operation.type === 'in_progress'
                                                ? 'text-blue-400'
                                                : 'text-gray-400'
                                }`}
                        >
                            {operation.type === 'completed'
                                ? 'Finalizado'
                                : operation.type === 'abandoned'
                                    ? 'Abandonado'
                                    : operation.type === 'replaced'
                                        ? 'Substituído'
                                        : operation.type === 'in_progress'
                                            ? 'Em andamento'
                                            : 'Indefinido'}
                        </p>

                    </div>

                    <div>
                        <p className="text-gray-400 text-sm font-semibold">Justificativa:</p>
                        <p className="text-gray-200 text-sm font-medium">
                            {operation.justification ? operation.justification : "Sem justificativa."}
                        </p>
                    </div>
                </div>
            </div>

          </div>
         <div className="w-2/3 flex flex-col sm:flex-col justify-center items-center bg-blue-50 p-4 rounded-2xl gap-4 min-h-full text-center">
                <div className='w-full flex items-center justify-center p-4 rounded-xl bg-[#02061B]'>
                    <QRCode
                        value={booster?.tip?.payload || ""}
                        size={250}
                        quietZone={4}
                        bgColor={'#02061B'}
                        fgColor={'white'}
                        eyeRadius={0}
                        ecLevel="L"
                    />

                </div>
                {amount > 0 && (
                    <p className="text-[0.95rem] font-normal text-gray-600">
                        Realize o pagamento de <span className='text-indigo-600 font-semibold'>R$ {Number(amount).toFixed(2)}</span> ao booster.
                    </p>
                )}
            </div>
        </div>
    );
};


const PayPopup: React.FC<PayPopupProps> = ({ row_order, onClose, moveOrderToTab }) => {
    const { token } = useAuth();
    const [order, setOrder] = useState<IRequestDTO | null>(null);
    const [currentOperationIndex, setCurrentOperationIndex] = useState<number>(0);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [showConfigSection, setShowConfigSection] = useState<boolean>(false);

    const handleToggleConfigSection = () => {
        setShowConfigSection((prevState) => !prevState);
    };

    useEffect(() => {
        const fetchOrder = async () => {
            if (!token) return;
            try {
                const response = await api.get(`/order/${row_order.id}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setOrder(response.data);
            } catch (error) {
                console.error('Erro ao buscar a ordem:', error);
            }
        };

        fetchOrder();
    }, [row_order, token]);

    if (!row_order || !order) return null;

    const approvedOperations = Array.isArray(order?.operations)
        ? order.operations.filter((operation) => operation.status === "approved")
        : [];

    const currentOperation =
        approvedOperations.length > 0
            ? approvedOperations[currentOperationIndex]
            : null;

            const handleOperationAction = async (action: 'paid' | 'reject') => {
                if (!currentOperation || !token) return;
            
                try {
                    await api.post(
                        `/order/pay`,
                        {
                            order_id: row_order.id,
                            operation_id: currentOperation.id,
                            payment_status: action,
                            amount: currentOperation.amount,
                        },
                        { headers: { Authorization: `Bearer ${token}` } }
                    );
            
                    const updatedOperations = [...approvedOperations];
                    updatedOperations[currentOperationIndex] = {
                        ...currentOperation,
                        payment_status: action,
                    };
            
                    setOrder((prevOrder) => ({
                        ...prevOrder!,
                        operations: updatedOperations,
                    }));
            
                    if (currentOperationIndex < approvedOperations.length - 1) {
                        setCurrentOperationIndex((prevIndex) => prevIndex + 1);
                    } else {
                        moveOrderToTab(order.id, "completed")
                        onClose(); 
                    }
                } catch (error) {
                    console.error(`Erro ao ${action} a operação:`, error);
                    
                    setErrorMessage(`Erro ao ${action === 'paid' ? 'aprovar' : 'rejeitar'} a operação.`);
                }
            };
            

    const handlePrevOperation = () => {
        if (approvedOperations.length > 0) {
            setCurrentOperationIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : approvedOperations.length - 1
            );
        }
    };

    const handleNextOperation = () => {
        if (approvedOperations.length > 0) {
            setCurrentOperationIndex((prevIndex) =>
                prevIndex < approvedOperations.length - 1 ? prevIndex + 1 : 0
            );
        }
    };

    const handleAmountChange = (newAmount: string) => {
        const amount = parseFloat(newAmount);
        if (isNaN(amount) || amount < 0) return;

        const totalAmount = approvedOperations.reduce((sum, operation) => sum + (operation.amount || 0), 0);

        if (amount <= order.price && totalAmount - (currentOperation?.amount || 0) + amount <= order.price) {
            if (currentOperation) {
                const updatedOperation = { ...currentOperation, amount };
                const updatedOperations = [...approvedOperations];
                updatedOperations[currentOperationIndex] = updatedOperation;

                setOrder((prevOrder) => ({
                    ...prevOrder!,
                    operations: updatedOperations,
                }));
            }
        } else {
            setErrorMessage("O valor da operação não pode exceder o preço do pedido ou a soma das operações.");
        }
    };


    return (
        <div className="fixed inset-0 bg-blue-50 bg-opacity-80 flex justify-center items-center z-50 transition-opacity duration-300">
            <motion.button
                onClick={handlePrevOperation}
                className="absolute left-8 top-1/2 p-3 bg-gray-800 text-white rounded-full z-50 shadow-lg"
                whileHover={{ scale: 1.1 }}
                transition={{ duration: 0.2 }}
                style={{
                    boxSizing: 'border-box',
                    width: '50px',
                    height: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                &lt;
            </motion.button>
            <div className="bg-blue-50 px-6 py-4 gap-4 flex flex-col items-center rounded-xl border-2 border-gray-700 shadow-lg w-1/3 md:w-3/4 lg:w-1/2 overflow-hidden relative">
                <div className="w-full flex justify-between items-center">
                    <h2 className="text-gray-200 text-lg font-bold">
                        Pedido: #{order.relative_id}
                    </h2>
                    <button onClick={onClose}>
                        <img src={Close} alt="Fechar" width={20} height={20} />
                    </button>
                </div>
                {currentOperation ? (
                    <motion.div
                        className="w-full flex flex-col items-center gap-4"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0, transition: { duration: 0.3 } }}
                        exit={{ opacity: 0, y: -20, transition: { duration: 0.3 } }}
                    >
                        <motion.div
                            className="w-full flex flex-row items-start gap-4 justify-between bg-blue-500 p-4 rounded-xl"
                            key={currentOperationIndex}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            <BoosterDetails operation={currentOperation} booster={currentOperation.booster} amount={currentOperation.amount} />
                        </motion.div>
                    </motion.div>
                ) : (
                    <motion.p
                        className="text-gray-500"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.3 }}
                    >
                        Nenhuma operação aprovada encontrada para este pedido.
                    </motion.p>
                )}

                {errorMessage && (
                    <div className="text-red-500 text-sm font-semibold mt-2">{errorMessage}</div>
                )}

                <div className="border border-gray-900 w-full bg-blue-50 rounded-md py-8 px-10 select-none">
                    <h3 className="text-lg text-indigo-600 font-semibold mb-4">Informações do Serviço</h3>
                    <div className="flex flex-row items-start justify-between">
                    <div className="flex flex-col gap-1 ">
                            <p className="text-sm font-semibold text-primary-200">Jogo:</p>
                            <img
                                src={order.game?.image || ""}
                                alt="Imagem do jogo"
                                className="rounded-md p-1.5 object-contain w-12 h-12"
                            />
                        </div>
                        <div className="flex flex-col gap-1">
                            <p className="text-sm font-semibold text-primary-200">Tipo de Boost:</p>
                            <p className="font-medium text-sm">{order.details.boostType}</p>
                        </div>
                        <div className="flex flex-col gap-1">
                            <p className="text-sm font-semibold text-primary-200">Descrição:</p>
                            <p className="font-medium text-sm">{order.details.description}</p>
                        </div>
                        <div className="flex flex-col gap-1">
                            <p className="text-sm font-semibold text-primary-200">Valor:</p>
                            <p className="font-medium text-sm">R$ {Number(order.price).toFixed(2)}</p>
                        </div>
                        <div className="flex flex-col gap-1 items-start justify-center">
                            <p className="text-sm font-semibold text-primary-200">Booster:</p>
                            <div className="flex flex-row gap-1 w-30">
                                <p className="font-medium text-sm">R$ {Number(currentOperation?.amount).toFixed(2)}</p>
                                <p className="font-medium text-sm text-gray-600">
                                    ({order.price > 0 ? Number((Number(currentOperation?.amount) / order.price) * 100).toFixed(2) : 0}%)</p>
                            </div>
                            <button
                                onClick={handleToggleConfigSection}
                                className="absolute mt-16 text-indigo-600 text-sm font-semibold underline hover:text-indigo-600"
                            >
                                Editar
                            </button>
                        </div>
                    </div>
                </div>

                {showConfigSection && (
                    <div className="border border-gray-900 w-full bg-blue-50 rounded-md py-8 px-10 select-none">
                        <h3 className="text-lg text-indigo-600 font-semibold mb-4">Configurações do Serviço</h3>
                        <div className="flex flex-row items-center justify-between">
                            <label className="text-gray-400 text-sm font-medium">
                                Valor a pagar ao booster
                            </label>
                            <input
                                type="number"
                                value={currentOperation?.amount || 0} 
                                onChange={(e) => handleAmountChange(e.target.value)}
                                className="p-3 border-2 rounded-lg border-indigo-600 bg-blue-500 text-white placeholder-gray-500 outline-none transition-all duration-200 ease-in-out"
                            />
                        </div>
                    </div>
                )}

                <div className="p-2 flex flex-row justify-end gap-3 w-full">
                    <button
                        type="button"
                        onClick={onClose}
                        className="focus:outline-none text-white bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-900"
                    >
                        Cancelar
                    </button>

                    <button
                        type="button"
                        onClick={() => handleOperationAction('reject')}
                        className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2"
                    >
                        Rejeitar
                    </button>

                    <button
                        type="button"
                        onClick={() => handleOperationAction('paid')}
                        className="focus:outline-none text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2"
                    >
                        Aprovar
                    </button>
                </div>
            </div>

            <motion.button
                onClick={handleNextOperation}
                className="absolute right-8 top-1/2 p-3 bg-gray-800 text-white rounded-full z-50 shadow-lg"
                whileHover={{ scale: 1.1 }}
                transition={{ duration: 0.2 }}
                style={{
                    boxSizing: 'border-box',
                    width: '50px',
                    height: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                &gt;
            </motion.button>
        </div>
    );
};




export default PayPopup;