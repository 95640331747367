import React, { useState, ReactNode } from 'react';
import { Pagination } from '../pagination';
import { IResponsePreferenceDTO } from '@components/Chat/types/IRequest';
import IconAndamento from "@assets/Table/IconAndamento.svg";
import { useTranslation } from 'react-i18next';
import { Tooltip } from "@material-tailwind/react";


interface TableHead {
    label: string;
    type: 'text' | 'image' | 'booster' | 'boosters' | 'money' | 'action' | 'optional' | 'justification' | 'status_payment' | 'status_approve' | 'preference' | 'status' | 'custom-id' | 'payment';
    key?: string;
}

interface TableProps {
    head: TableHead[];
    rows: {
        [key: string]: any;
    }[];
    actions?: (row: { [key: string]: any }) => ReactNode;
    onRowClick?: (row: { [key: string]: any }) => void;
    recordsLimit?: number;
}

const getValueFromKey = (obj: { [key: string]: any }, key: string) => {
    return key.split('.').reduce((o, k) => (o || {})[k], obj);
};

const DataTable: React.FC<TableProps> = ({ head, rows, actions, onRowClick, recordsLimit = 10 }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(rows.length / recordsLimit);
    const startIndex = (currentPage - 1) * recordsLimit;
    const endIndex = startIndex + recordsLimit;
    const currentRows = rows.slice(startIndex, endIndex);
    const [hoveredId, setHoveredId] = useState<string | null>(null);
    const [hoveredIdPreference, setHoveredIdPreference] = useState<string | null>(null);

    const { t } = useTranslation();


    const handleRowClick = (row: { [key: string]: any }) => {
        if (onRowClick) {
            onRowClick(row);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrev = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleMouseEnter = (id: string) => setHoveredId(id);
    const handleMouseLeave = () => setHoveredId(null);

    const handleMouseEnterPref = (id: string) => setHoveredIdPreference(id);
    const handleMouseLeavePref = () => setHoveredIdPreference(null);

    interface Champion {
        image: string;
        name: string;
    }

    const renderStatus = (status: string) => {
        switch (status) {
            case "approved":
                return (
                    <div className="bg-[#56BD79] bg-opacity-10 border-2 border-[#56BD79] p-2 px-4 rounded-full flex items-center justify-center gap-2">
                        <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M11.17 14.21L9.1275 12.1675C8.95333 11.9933 8.73167 11.9063 8.4625 11.9063C8.19333 11.9063 7.97167 11.9933 7.7975 12.1675C7.62333 12.3417 7.53625 12.5633 7.53625 12.8325C7.53625 13.1017 7.62333 13.3233 7.7975 13.4975L10.505 16.205C10.695 16.395 10.9167 16.49 11.17 16.49C11.4233 16.49 11.645 16.395 11.835 16.205L17.2025 10.8375C17.3767 10.6633 17.4638 10.4417 17.4638 10.1725C17.4638 9.90333 17.3767 9.68168 17.2025 9.5075C17.0283 9.33333 16.8067 9.24625 16.5375 9.24625C16.2683 9.24625 16.0467 9.33333 15.8725 9.5075L11.17 14.21ZM12.5 22C11.1858 22 9.95083 21.7505 8.795 21.2514C7.63917 20.7523 6.63375 20.0757 5.77875 19.2212C4.92375 18.3662 4.24703 17.3608 3.7486 16.205C3.25017 15.0492 3.00063 13.8142 3 12.5C3 11.1858 3.24953 9.95083 3.7486 8.795C4.24767 7.63917 4.92438 6.63375 5.77875 5.77875C6.63375 4.92375 7.63917 4.24703 8.795 3.7486C9.95083 3.25017 11.1858 3.00063 12.5 3C13.8142 3 15.0492 3.24953 16.205 3.7486C17.3608 4.24767 18.3662 4.92438 19.2212 5.77875C20.0762 6.63375 20.7532 7.63917 21.2524 8.795C21.7515 9.95083 22.0006 11.1858 22 12.5C22 13.8142 21.7505 15.0492 21.2514 16.205C20.7523 17.3608 20.0757 18.3662 19.2212 19.2212C18.3662 20.0762 17.3608 20.7532 16.205 21.2523C15.0492 21.7515 13.8142 22.0006 12.5 22Z"
                                fill="#56BD79"
                            />
                        </svg>

                        <span className="text-[#56BD79] font-semibold">
                            {t("table.approved")}
                        </span>
                    </div>
                );

            case "pending":
                return (
                    <div className="bg-[#ffb13c] bg-opacity-10 backdrop-blur-md border-2 border-[#ffb13c] p-2 px-4 rounded-full flex items-center justify-center gap-2">
                        <div
                            className="animate-spin h-6 w-6 rounded-full"
                            style={{
                                border: "2px dashed #ffb13c",
                            }}
                        ></div>
                        <span className="text-[#ffb13c] font-semibold">Pendente</span>
                    </div>
                );

            case "reject":
                return (
                    <div className="bg-[#FF4D4D] bg-opacity-10 border-2 border-[#FF4D4D] p-2 px-4 rounded-full flex items-center justify-center gap-2">
                        <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M11.17 14.21L9.1275 12.1675C8.95333 11.9933 8.73167 11.9063 8.4625 11.9063C8.19333 11.9063 7.97167 11.9933 7.7975 12.1675C7.62333 12.3417 7.53625 12.5633 7.53625 12.8325C7.53625 13.1017 7.62333 13.3233 7.7975 13.4975L10.505 16.205C10.695 16.395 10.9167 16.49 11.17 16.49C11.4233 16.49 11.645 16.395 11.835 16.205L17.2025 10.8375C17.3767 10.6633 17.4638 10.4417 17.4638 10.1725C17.4638 9.90333 17.3767 9.68168 17.2025 9.5075C17.0283 9.33333 16.8067 9.24625 16.5375 9.24625C16.2683 9.24625 16.0467 9.33333 15.8725 9.5075L11.17 14.21ZM12.5 22C11.1858 22 9.95083 21.7505 8.795 21.2514C7.63917 20.7523 6.63375 20.0757 5.77875 19.2212C4.92375 18.3662 4.24703 17.3608 3.7486 16.205C3.25017 15.0492 3.00063 13.8142 3 12.5C3 11.1858 3.24953 9.95083 3.7486 8.795C4.24767 7.63917 4.92438 6.63375 5.77875 5.77875C6.63375 4.92375 7.63917 4.24703 8.795 3.7486C9.95083 3.25017 11.1858 3.00063 12.5 3C13.8142 3 15.0492 3.24953 16.205 3.7486C17.3608 4.24767 18.3662 4.92438 19.2212 5.77875C20.0762 6.63375 20.7532 7.63917 21.2524 8.795C21.7515 9.95083 22.0006 11.1858 22 12.5C22 13.8142 21.7505 15.0492 21.2514 16.205C20.7523 17.3608 20.0757 18.3662 19.2212 19.2212C18.3662 20.0762 17.3608 20.7532 16.205 21.2523C15.0492 21.7515 13.8142 22.0006 12.5 22Z"
                                fill="#FF4D4D"
                            />
                        </svg>
                        <span className="text-[#FF4D4D] font-semibold">Rejeitado</span>
                    </div>
                );

            default:
                return null;
        }
    };


    const renderPagamento = (paymentStatus: string) => {
        switch (paymentStatus) {
            case "approved":
                return (
                    <div className="bg-[#56BD79] bg-opacity-10 border-2 border-[#56BD79] p-2 px-4 rounded-full flex items-center justify-center gap-2">
                        <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12.1253 17.6996C10.0653 17.6996 9.25531 16.7796 9.14531 15.5996H6.94531C7.06531 17.7896 8.70531 19.0196 10.6253 19.4296V21.5996H13.6253V19.4496C15.5753 19.0796 17.1253 17.9496 17.1253 15.8996C17.1253 13.0596 14.6953 12.0896 12.4253 11.4996C10.1553 10.9096 9.42531 10.2996 9.42531 9.34961C9.42531 8.25961 10.4353 7.49961 12.1253 7.49961C13.9053 7.49961 14.5653 8.34961 14.6253 9.59961H16.8353C16.7653 7.87961 15.7153 6.29961 13.6253 5.78961V3.59961H10.6253V5.75961C8.68531 6.17961 7.12531 7.43961 7.12531 9.36961C7.12531 11.6796 9.03531 12.8296 11.8253 13.4996C14.3253 14.0996 14.8253 14.9796 14.8253 15.9096C14.8253 16.5996 14.3353 17.6996 12.1253 17.6996Z"
                                fill="#56BD79"
                            />
                        </svg>
                        <span className="text-[#56BD79] font-semibold">
                            {t("table.received")}
                        </span>
                    </div>
                );

            case "pending":
                return (
                    <div className="bg-[#ffb13c] bg-opacity-10 backdrop-blur-md border-2 border-[#ffb13c] p-2 px-4 rounded-full flex items-center justify-center gap-2">
                        <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12.6255 7.49961C14.4055 7.49961 15.0655 8.34961 15.1255 9.59961H17.3355C17.2655 7.87961 16.2155 6.29961 14.1255 5.78961V3.59961H11.1255V5.75961C10.7355 5.83961 10.3755 5.96961 10.0255 6.11961L11.5355 7.62961C11.8555 7.54961 12.2255 7.49961 12.6255 7.49961ZM5.59555 4.51961L4.18555 5.92961L7.62555 9.36961C7.62555 11.4496 9.18555 12.5896 11.5355 13.2796L15.0455 16.7896C14.7055 17.2796 13.9955 17.6996 12.6255 17.6996C10.5655 17.6996 9.75555 16.7796 9.64555 15.5996H7.44555C7.56555 17.7896 9.20555 19.0196 11.1255 19.4296V21.5996H14.1255V19.4496C15.0855 19.2696 15.9555 18.8996 16.5855 18.3296L18.8055 20.5496L20.2155 19.1396L5.59555 4.51961Z"
                                fill="#ffb13c"
                            />
                        </svg>
                        <span className="text-[#ffb13c] font-semibold">Pendente</span>
                    </div>
                );

            case "reject":
                return (
                    <div className="bg-[#FF4D4D] bg-opacity-10 border-2 border-[#FF4D4D] p-2 px-4 rounded-full flex items-center justify-center gap-2">
                        <svg
                            width="25"
                            height="25"
                            viewBox="0 0 25 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12.1253 17.6996C10.0653 17.6996 9.25531 16.7796 9.14531 15.5996H6.94531C7.06531 17.7896 8.70531 19.0196 10.6253 19.4296V21.5996H13.6253V19.4496C15.5753 19.0796 17.1253 17.9496 17.1253 15.8996C17.1253 13.0596 14.6953 12.0896 12.4253 11.4996C10.1553 10.9096 9.42531 10.2996 9.42531 9.34961C9.42531 8.25961 10.4353 7.49961 12.1253 7.49961C13.9053 7.49961 14.5653 8.34961 14.6253 9.59961H16.8353C16.7653 7.87961 15.7153 6.29961 13.6253 5.78961V3.59961H10.6253V5.75961C8.68531 6.17961 7.12531 7.43961 7.12531 9.36961C7.12531 11.6796 9.03531 12.8296 11.8253 13.4996C14.3253 14.0996 14.8253 14.9796 14.8253 15.9096C14.8253 16.5996 14.3353 17.6996 12.1253 17.6996Z"
                                fill="#FF4D4D"
                            />
                        </svg>
                        <span className="text-[#FF4D4D] font-semibold">Rejeitado</span>
                    </div>
                );

            default:
                return null;
        }
    };


    const formatCellValue = (
        type: 'text' | 'image' | 'booster' | 'boosters' | 'money' | 'action' | 'justification' | 'status_payment' | 'status_approve' | 'preference' | 'optional' | 'status' | 'custom-id' | 'payment',
        value: any,
        row: { [key: string]: any },
        actions?: (row: { [key: string]: any }) => ReactNode
    ) => {
        if (value == null && type != "action" && type != "money") {
            return "Desconhecido";
        }

        switch (type) {
            case 'text':
                return <span className='whitespace-nowrap'>{typeof value === 'string' ? value : JSON.stringify(value)}</span>;
            case 'text':
                if (value) {
                    return (
                        <div className="bg-[#56BD79] p-2 px-4 rounded-full flex items-center justify-center gap-2">
                            <svg
                                width="25"
                                height="25"
                                viewBox="0 0 25 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12.1253 17.6996C10.0653 17.6996 9.25531 16.7796 9.14531 15.5996H6.94531C7.06531 17.7896 8.70531 19.0196 10.6253 19.4296V21.5996H13.6253V19.4496C15.5753 19.0796 17.1253 17.9496 17.1253 15.8996C17.1253 13.0596 14.6953 12.0896 12.4253 11.4996C10.1553 10.9096 9.42531 10.2996 9.42531 9.34961C9.42531 8.25961 10.4353 7.49961 12.1253 7.49961C13.9053 7.49961 14.5653 8.34961 14.6253 9.59961H16.8353C16.7653 7.87961 15.7153 6.29961 13.6253 5.78961V3.59961H10.6253V5.75961C8.68531 6.17961 7.12531 7.43961 7.12531 9.36961C7.12531 11.6796 9.03531 12.8296 11.8253 13.4996C14.3253 14.0996 14.8253 14.9796 14.8253 15.9096C14.8253 16.5996 14.3353 17.6996 12.1253 17.6996Z"
                                    fill="#FFFFFF"
                                />
                            </svg>
                            <span className="text-gray-500 font-semibold">
                                {t("table.received")}
                            </span>
                        </div>
                    );
                } else {
                    return (
                        <div className="bg-[#ffb13c] p-2 px-4 rounded-full flex items-center justify-center gap-2">
                            <svg
                                width="25"
                                height="25"
                                viewBox="0 0 25 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M12.6255 7.49961C14.4055 7.49961 15.0655 8.34961 15.1255 9.59961H17.3355C17.2655 7.87961 16.2155 6.29961 14.1255 5.78961V3.59961H11.1255V5.75961C10.7355 5.83961 10.3755 5.96961 10.0255 6.11961L11.5355 7.62961C11.8555 7.54961 12.2255 7.49961 12.6255 7.49961ZM5.59555 4.51961L4.18555 5.92961L7.62555 9.36961C7.62555 11.4496 9.18555 12.5896 11.5355 13.2796L15.0455 16.7896C14.7055 17.2796 13.9955 17.6996 12.6255 17.6996C10.5655 17.6996 9.75555 16.7796 9.64555 15.5996H7.44555C7.56555 17.7896 9.20555 19.0196 11.1255 19.4296V21.5996H14.1255V19.4496C15.0855 19.2696 15.9555 18.8996 16.5855 18.3296L18.8055 20.5496L20.2155 19.1396L5.59555 4.51961Z"
                                    fill="#FFFFFF"
                                />
                            </svg>
                            <span className="text-gray-500 font-semibold">Pendente</span>
                        </div>
                    );
                }
            case 'custom-id':
                return <span className="bg-blue-1001 text-gray-500 font-semibold rounded-full px-6 py-2 inline-flex flex-row justify-center items-center">
                    #{value}
                </span>
            case 'money':
                if (!value) {
                    return (
                        <span className="text-gray-100 dark:text-gray-500 text-md">
                            Em processamento
                        </span>
                    );
                }
                return (
                    <span className="text-gray-100 dark:text-gray-500 text-md">
                        <span className="text-primary-200 font-semibold">R$</span>
                        {Number(value)
                            .toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
                            .slice(2)}
                    </span>
                );
            case 'status':
                if (value === "booster_in_progress") {
                    return (
                        <div className="bg-blue-1001 rounded-full w-auto inline-flex text-gray-500 font-semibold justify-center items-center px-4 py-2 gap-2">
                            Em andamento
                            <img src={IconAndamento} alt="Andamento" />
                        </div>
                    );
                } else if (value === "booster_finished") {
                    return <span className="text-gray-500 font-semibold">Pendente</span>;
                }
                break;
            case 'booster':
                return (
                    <span className='transition-colors duration-1000 p-2 px-4 font-semibold border border-blue-1001 bg-blue-50 dark:bg-blue-1001/20 inline-flex rounded-full'>
                        {typeof value === 'string' ? value : JSON.stringify(value)}
                    </span>
                );

            case 'boosters':
                return (
                    <div className="transition-colors duration-1000 flex flex-row gap-4 items-center justify-center">
                        {(() => {
                            let parsedValue;
                            try {
                                parsedValue = typeof value === 'string' ? JSON.parse(value) : value;
                            } catch {
                                parsedValue = value;
                            }

                            if (Array.isArray(parsedValue)) {
                                return parsedValue.map((item, index) => {
                                    const championName = item?.booster?.champion_name || 'Champion não encontrado';
                                    return (
                                        <span
                                            key={index}
                                            className="p-2 px-4 font-semibold border border-blue-1001 bg-blue-50 dark:bg-blue-1001/20 inline-flex rounded-full space-x-2"
                                        >
                                            {championName}
                                        </span>
                                    );
                                });
                            }

                            return 'Nenhum booster encontrado';
                        })()}
                    </div>
                );


            case 'image':
                return typeof value === 'string' ? (
                    <div className='flex items-center justify-center w-full'>
                        <img src={value} alt="table-image" className="bg-[#161A2D] rounded-md h-10 w-10 p-2 object-contain border border-gray-700" />
                    </div>
                ) : null;
            case 'preference':
                const remainingPreferences = value.length - 1;
                const ignoredTypes = ["lp_additional_required", "current_required", "desired_required"];

                const validPreferences = value.filter((preference: IResponsePreferenceDTO) =>
                    !ignoredTypes.includes(preference.type)
                );

                return (
                    <div className="flex flex-col items-center justify-center space-y-1">
                        <Tooltip
                            className="bg-transparent"
                            interactive={true}
                            open={hoveredIdPreference === row.id}
                            content={
                                <>
                                    {validPreferences.length > 0 && hoveredIdPreference === row.id && (
                                        <div
                                            className="bg-blue-50 bg-opacity-60 backdrop-blur-md w-[200px] border border-secondary-500 rounded-xl text-gray-500 font-normal text-sm p-4 shadow-lg transition duration-300"
                                        >
                                            {validPreferences.slice(1)
                                                .map((preference: IResponsePreferenceDTO, index: any) => {
                                                    if (preference.type === 'selected_champions_required') {
                                                        return (
                                                            <div key={index} className="mt-2 flex flex-col items-center gap-2 justify-center">
                                                                <span className="font-semibold text-primary-200">{preference.label}</span>
                                                                <div className="grid grid-cols-4 gap-2 mt-1">
                                                                    {Array.isArray(preference.option.value) && preference.option.value.map((champion: Champion, index: number) => (
                                                                        <div key={index} className="w-8 h-8">
                                                                            <img
                                                                                src={`https://ddragon.leagueoflegends.com/cdn/13.14.1/img/champion/${champion.image}`}
                                                                                alt={champion.name}
                                                                                className="w-8 h-8 rounded-full object-cover"
                                                                            />
                                                                        </div>
                                                                    ))}

                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                    return (
                                                        <div key={index} className="mt-2 flex flex-col items-center gap-2 justify-center">
                                                            <span className="font-semibold text-primary-200">{preference.label}</span>
                                                            <span>{preference.option.label}</span>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    )}
                                </>
                            }>
                            <div
                                className="flex flex-col items-center justify-center space-y-1"
                                onMouseEnter={() => handleMouseEnterPref(row.id)}
                                onMouseLeave={handleMouseLeavePref}
                            >
                                {validPreferences.length > 0 ? (
                                    <>
                                        <span className="font-semibold text-gray-100 dark:text-gray-500">{validPreferences[0].label}</span>
                                        <span className="text-gray-500 dark:text-gray-400">{validPreferences[0].option.label}</span>
                                    </>
                                ) : (
                                    <span className="text-gray-500 dark:text-gray-400">Nenhuma preferência disponível</span>
                                )}
                                {remainingPreferences > 0 && (
                                    <span className="text-primary-200 text-sm mt-2 cursor-pointer">
                                        +{remainingPreferences} remain...
                                    </span>
                                )}
                            </div>
                        </Tooltip>
                    </div>
                );

            case 'optional':
                return (
                    <div className="text-center flex items-center justify-center">
                        <Tooltip
                            className="bg-transparent"
                            interactive={true}
                            open={hoveredId === row.id}
                            content={
                                <>
                                    <div
                                        className="bg-blue-50 bg-opacity-60 backdrop-blur-md opacity-1 w-[200px] border border-secondary-500 rounded-xl text-gray-500 font-normal text-sm p-4 shadow transition duration-300"
                                        onMouseEnter={() => handleMouseEnter(row.id)}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        {value.map((text: any, index: number) => (
                                            <h3
                                                key={index}
                                                className="text-gray-500 text-xs py-2 px-4 rounded-full"
                                            >
                                                {text.label.trim()}
                                            </h3>
                                        ))}
                                    </div>
                                </>
                            }>

                            <div
                                className="flex items-center justify-center w-10 h-10 border-2 border-primary-500 rounded-full p-2 cursor-pointer "
                                onMouseEnter={() => value && value.length > 0 && handleMouseEnter(row.id)}
                                onMouseLeave={handleMouseLeave}
                            >
                                {value && value.length > 0 ? value.length : "0"}
                            </div>
                        </Tooltip>
                    </div>
                );
            case 'justification':
                let parsed;
                try {
                    parsed = typeof value === 'string' ? JSON.parse(value) : value;
                } catch {
                    parsed = value;
                }

                const abandonedOperation = parsed?.find((operation: any) => operation.type === 'abandoned');

                const justification = abandonedOperation ? abandonedOperation.justification : 'Sem justificativa';

                return (
                    <span className="text-gray-500 font-semibold">
                        {justification}
                    </span>
                );
            case 'status_approve':
                let parsedValuee;
                try {
                    parsedValuee = typeof value === 'string' ? JSON.parse(value) : value;
                } catch {
                    parsedValuee = value;
                }

                return (
                    renderStatus(parsedValuee)
                );
            case 'status_payment':
                let parsedValue;
                try {
                    parsedValue = typeof value === 'string' ? JSON.parse(value) : value;
                } catch {
                    parsedValue = value;
                }

                return (
                    renderPagamento(parsedValue)
                );

            case 'action':
                return actions ? actions(row) : null;
            default:
                return null;
        }
    };

    return (
        <div className='flex w-full h-full flex-col gap-2 items-center justify-center'>
            <div className="flex w-full overflow-auto max-h-[570px]">
                <table className="table-auto w-full">
                    <thead>
                        <tr>
                            {head.map((column, index) => (
                                <th
                                    key={index}
                                    className="whitespace-nowrap transition-colors duration-1000 text-gray-100 dark:text-gray-500 text-base text-center px-4 py-2"
                                >
                                    {column.label}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {currentRows.length === 0 ? (
                            <tr>
                                <td colSpan={head.length} className="text-center text-gray-500 py-4">
                                    Nenhum registro encontrado.
                                </td>
                            </tr>
                        ) : (
                            currentRows.map((data, index) => (
                                <tr
                                    key={index}
                                    className="transition-transform duration-600 ease-in transform hover:-translate-y-1 hover:z-10"
                                    onClick={() => handleRowClick(data)}
                                >
                                    {head.map((column, i) => (
                                        <td
                                            key={i}
                                            className="transition-colors duration-1000 text-primary-100 dark:text-gray-500 text-sm font-normal text-center px-4 py-4"
                                        >
                                            {formatCellValue(
                                                column.type,
                                                getValueFromKey(data, column.key || column.label.toLowerCase()),
                                                data,
                                                actions
                                            )}
                                        </td>
                                    ))}
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
            {totalPages > 1 && (
                <>
                    <hr className="my-4 w-full border-gray-600 dark:border-gray-800" />
                    <Pagination
                        active={currentPage}
                        totalPages={totalPages}
                        onNext={handleNext}
                        onPrev={handlePrev}
                    />
                </>
            )}
        </div>
    );
};

export default DataTable;
