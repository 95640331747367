import { AxiosRequestConfig } from "axios";
import { getDefaultAxiosConfig } from "../checkout";
import api from "@services/client/api";

export interface AbandonServiceData {
    order_id: string;
    reason: string;
}

export interface ScreenshotData {
    id: string;
    file: File;
}

export interface ScreenshotRemoveData {
    id: string;
    file: string;
}


export async function sendAbandonRequest(token: string, data: AbandonServiceData): Promise<any> {
    try {
        if (!token) {
            throw new Error("Token de autenticação não fornecido.");
        }
        const config: AxiosRequestConfig = getDefaultAxiosConfig(token);
        const response = await api.post<any>('/order/abandon-service', data, config);
        return response.data;
    } catch (error: any) {
        throw new Error(error.response.data.error || "Não foi possível abandonar o serviço");
    }
};

export interface CompleteOrderData {
    operation_id: string;
    order_id: string;
  }
  
  export async function sendCompleteRequest(
    token: string,
    data: CompleteOrderData
  ): Promise<{ message: string }> {
    try {
      if (!token) {
        throw new Error("Token de autenticação não fornecido.");
      }
  
      const config: AxiosRequestConfig = getDefaultAxiosConfig(token);
      const response = await api.post<any>("/order/complete-service", data, config);
      return response.data;
    } catch (error: any) {
        console.log(error)
      throw new Error(
        error.response?.data?.message || "Erro ao concluir o serviço"
      );
    }
  }


/**
 * Envia uma captura de tela para upload.
 * @param token Token de autenticação.
 * @param data Dados necessários para o upload.
 */
export async function uploadScreenshot(token: string, data: ScreenshotData): Promise<{ message: string, id: string, file: string } | null> {
    try {
        if (!token) {
            throw new Error("Token de autenticação não fornecido.");
        }

        const config: AxiosRequestConfig = getDefaultAxiosConfig(token);
        const formData = new FormData();
        formData.append("file", data.file);

        const response = await api.post<any>(
            `/order/screenshots/${data.id}`,
            formData,
            {
                ...config,
                headers: {
                    ...config.headers,
                    "Content-Type": "multipart/form-data",
                },
            }
        );

        return response.data;
    } catch (error: any) {
        const errorMessage = error.response?.data?.error || error.message || "Erro ao fazer upload de screenshot";
        throw new Error(errorMessage);
    }
}

/**
 * Obtém capturas de tela associadas a uma operação.
 * @param token Token de autenticação.
 * @param operationId ID da operação.
 */

export async function getScreenshots(token: string, operationId: string): Promise<{id: string, link: string}[]> {
    try {
        if (!token) {
            throw new Error("Token de autenticação não fornecido.");
        }

        const config: AxiosRequestConfig = getDefaultAxiosConfig(token);
        const response = await api.get<any>(`/order/screenshots/${operationId}`, config);

        return response.data;
    } catch (error: any) {
        throw new Error(error.response?.data || "Erro ao buscar screenshots");
    }
}

/**
 * Remove uma captura de tela específica.
 * @param token Token de autenticação.
 * @param data Dados necessários para remover a captura.
 */
export async function removeScreenshot(token: string, data: ScreenshotRemoveData): Promise<any> {
    try {
        if (!token) {
            throw new Error("Token de autenticação não fornecido.");
        }

        const config: AxiosRequestConfig = getDefaultAxiosConfig(token);
        const response = await api.delete<any>(
            `/order/screenshots/${data.id}`,
            {
                ...config,
                data: { filename: data.file },
            }
        );

        return response.data;
    } catch (error: any) {
        throw new Error(error.response?.data?.error || "Erro ao remover screenshot");
    }
}
