import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import api from "@services/client/api";
import DataTable from "@components/Dashboard/mainDashboard/Adm/requestFinished/generic-table";
import Process from "@assets/Table/HistoricTable.svg";
import { IRequestDTO } from "@components/Chat/types/IRequest";
import Arrow from "@assets/Table/Arrow.svg";


interface InProgressProps {
    token: string;
}

const TABLE_HEADS = [
    { label: "ID", type: "custom-id" as const, key: "relative_id" },
    { label: "Client", type: "text" as const, key: "client.nick_user" },
    { label: "Description", type: "text" as const, key: "details.description" },
    { label: "Boost Type", type: "text" as const, key: "details.boostType" },
    { label: "Status", type: "status" as const, key: "status" },
    { label: "Actions", type: "action" as const },
];

const InProgressBooster: React.FC<InProgressProps> = ({ token }) => {
    const { t } = useTranslation();
    const [data, setData] = useState<IRequestDTO[]>([]);

    const fetchData = async () => {
        try {
            const response = await api.get(`/order/booster/in-progress`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setData(response.data);
        } catch (error) {
            console.error("Erro ao buscar dados:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [token]);

    const handleActionClick = (row: { [key: string]: any }) => {

    };

    return (
        <div className="transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5] dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-2xl p-4 border border-gray-100 dark:border-secondary-500 mt-4">
            <div className="flex items-center justify-between">
                <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base flex items-center gap-2">
                    <img src={Process} alt={Process} />
                    {t("table.currentTitle")}
                </h2>
            </div>

            <div className="tab-content bg-white-100 dark:bg-blue-50 rounded-2xl p-4 mt-4">
                <DataTable
                    head={TABLE_HEADS}
                    rows={data}
                    recordsLimit={15}
                    actions={(row: any) => (
                        <button className="p-2 border-none" onClick={() =>
                            (window.location.href = `/dashboard/chat/${row.id}`)
                        }>
                            <img src={Arrow} alt={Arrow} />
                        </button>
                    )}
                />
            </div>
        </div>
    );
};

export default InProgressBooster;
