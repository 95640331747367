import React, { useState, useEffect } from "react";
import api from "@services/client/api";
import { toast } from "react-toastify";

interface Booster {
  id: number;
  championName: string;
  championImage: string;
}

interface DropdownUsersProps {
  game: string;
  token: string;
  orderId: string;
  onAssignBooster: () => void;
  moveOrderToTab: (orderId: string, newTab: string) => void;
}

const DropdownUsers: React.FC<DropdownUsersProps> = ({ game, token, orderId, onAssignBooster, moveOrderToTab }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [boosters, setBoosters] = useState<Booster[]>([]);
  const [selectedBooster, setSelectedBooster] = useState<Booster | null>(null); 
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchBoosters = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await api.get(`order/boosters/list/${game}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setBoosters(response.data.boosters);
      } catch (err) {
        setError("Erro ao carregar boosters.");
      } finally {
        setLoading(false);
      }
    };

    fetchBoosters();
  }, [game, token]);

  const filteredBoosters = boosters.filter((booster) =>
    booster.championName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleBoosterSelect = (booster: Booster) => {
    setSelectedBooster(booster); 
  };

  const handleAssignBooster = async () => {
    onAssignBooster();
    if (selectedBooster && orderId) {
      try {
        const response = await api.post(
          `order/assign`,
          {
            order_id: orderId,
            booster_id: selectedBooster.id,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success(response.data.message || "Booster atribuído com sucesso!");
        moveOrderToTab(orderId, "booster_in_progress")
        setSelectedBooster(null);
      } catch (err: any) {
        toast.error(err.response.data.message || "Erro ao atribuir booster.");
      }
    }
  };
  
  return (
    <div className="border-2 border-gray-900 rounded-lg w-60 bg-blue-50 py-2">
      <div className="px-4 py-2">
        <input
          type="text"
          placeholder="Pesquisar booster..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full p-2 text-gray-300 bg-blue-20 border border-gray-800 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-600"
        />
      </div>

      <ul className="h-50 max-h-60 py-2 overflow-y-auto text-white mr-2 my-2">
        {loading && <li className="px-4 py-2 text-gray-500">Carregando...</li>}
        {error && <li className="px-4 py-2 text-red-500">{error}</li>}
        {filteredBoosters.length === 0 && !loading ? (
          <li className="px-4 py-2 text-gray-500">Nenhum booster encontrado.</li>
        ) : (
          filteredBoosters.map((booster) => (
            <li key={booster.id}>
              <button
                onClick={() => handleBoosterSelect(booster)}
                className={`w-full rounded-md mt-1 flex items-center px-4 py-2 ${
                  selectedBooster?.id === booster.id
                    ? "bg-indigo-600" 
                    : "hover:bg-indigo-600"
                } dark:hover:text-white transition-all duration-200 ease-in-out`}
              >
                <img
                  className="w-6 h-6 me-2 rounded-full"
                  src={booster.championImage}
                  alt={`${booster.championName} image`}
                />
                {booster.championName}
              </button>
            </li>
          ))
        )}
      </ul>
      {selectedBooster && (
        <div className="px-4 mb-2">
          <button
            onClick={handleAssignBooster}
            className="w-full bg-indigo-600 text-white p-2 rounded-lg hover:bg-indigo-700"
          >
            Atribuir
          </button>
        </div>
      )}
    </div>
  );
};

export default DropdownUsers;
