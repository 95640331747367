import { IRequestDTO } from "@components/Chat/types/IRequest";
import { getScreenshots, removeScreenshot, uploadScreenshot } from "@services/server/(order)";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { File, Loader } from "react-feather";
import { Accept, useDropzone } from "react-dropzone";
import { Screenshot } from "..";
  
  interface ScreenshotUploadProps {
    order: IRequestDTO | null;
    token: string;
    screenshots: Screenshot[];
    setScreenshots: (screenshots: Screenshot[]) => void;
    selectedImage: Screenshot | null;
    setSelectedImage: (imageId: Screenshot | null) => void;
    handleImageClick: (index: number) => void;
    handleAddImageClick: (index: number) => void;
    updateScreenshots: (action: 'add' | 'remove', payload: Screenshot | number) => void;
    loading: boolean;
    setLoading: (loading: boolean) => void;
  }
  
  const ScreenshotUpload: React.FC<ScreenshotUploadProps> = ({
    order,
    token,
    screenshots,
    setScreenshots,
    selectedImage,
    setSelectedImage,
    loading,
    setLoading,
    handleImageClick,
    handleAddImageClick,
    updateScreenshots
  }) => {

    useEffect(() => {
        const fetchScreenshots = async () => {
            if (!order || !order.operation || Array.isArray(order.operation)) return;

            try {
                const data = await getScreenshots(token, order.operation.id);
   
                const formattedScreenshots = data.map((item: { id: string; link: string }) => ({
                    id: item.id,
                    file: `${process.env.REACT_APP_API_URL}${item.link}`,
                }));
                setScreenshots(formattedScreenshots);

                if (data.length > 0) {
                    setSelectedImage(formattedScreenshots[0]);
                }
            } catch (error) {
                toast.error("Erro ao carregar as screenshots.");
            }
        };

        fetchScreenshots();
    }, [order?.id]);


    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*' as unknown as Accept,
        onDrop: async (acceptedFiles) => {
            const remainingSlots = Math.max(0, 4 - screenshots.length);
            const newFiles = acceptedFiles.slice(0, remainingSlots);

            if (!order || !newFiles) return;

            setLoading(true);

            try {
                const data = await uploadScreenshot(token, { id: order.id, file: newFiles[0] });
                if (!data) return;

                const screenshotData = { 
                    id: data.id, 
                    file: `${process.env.REACT_APP_API_URL}/${data.file}`
                };

   
                updateScreenshots('add', screenshotData)

                if (!selectedImage) setSelectedImage(screenshotData);

            } catch (error: any) {
                toast.error(error.error || "Erro ao fazer upload da imagem.");
            } finally {
                setLoading(false);
            }
        },
    });

    const handleRemoveImage = async (index: number) => {
        const imageToRemove = screenshots[index];
        if (!order || !order.operation || Array.isArray(order.operation)) return;

        try {
            await removeScreenshot(token, { id: order.operation.id, file: imageToRemove.id });
            updateScreenshots('remove', index)
            if (selectedImage && selectedImage.id === imageToRemove.id) {
                setSelectedImage(
                    screenshots.length > 1
                        ? screenshots[(index - 1 + screenshots.length) % screenshots.length]
                        : null
                );
            }            
        } catch (error: any) {
            toast.error(error.error || "Erro ao fazer upload da imagem.");
        }
    };


    const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];

        if (!order) return null;

        if (file) {
            try {
                const data = await uploadScreenshot(token, { id: order.id, file });
                if (!data) return;

                const screenshotData = { 
                    id: data.id, 
                    file: `${process.env.REACT_APP_API_URL}/${data.file}`
                };

                updateScreenshots('add', screenshotData)

                setSelectedImage(screenshotData);

            } catch (error: any) {
                toast.error(error.error || "Erro ao fazer upload da imagem.");
            }
        }
    };

    if (!order) return null;

    return (
        <div className="flex flex-row items-start justify-between gap-4 w-full h-full">
            <div className="flex flex-col justify-between items-center gap-4">
                {screenshots.map((image, index) => (
                    <div
                        key={image.id || index}
                        className="relative w-20 h-20 overflow-hidden rounded-md group transform hover:scale-105 transition-transform duration-200"
                        onClick={() => handleImageClick(index)}
                    >
                        <img
                            src={typeof image.file === 'string' ? image.file : URL.createObjectURL(image.file)}
                            alt={`Screenshot ${index + 1}`}
                            className={`w-full h-full object-cover rounded-xl cursor-pointer p-0.5 ${selectedImage && image.id === selectedImage.id ? "border-2 border-dashed border-primary-500" : "border-2 border-dashed border-gray-600"}`}
                        />
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                handleRemoveImage(index);
                            }}
                            className="absolute top-1 right-1 bg-primary-200 text-white p-1 rounded-full text-xs opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M19 7l-1 12a2 2 0 01-2 2H8a2 2 0 01-2-2L5 7m5-4h4m-4 0h4m-4 0V3a1 1 0 011-1h2a1 1 0 011 1v2M5 7h14"
                                />
                            </svg>
                        </button>
                    </div>
                ))}


                {screenshots.length < 4 &&
                    Array.from({ length: 4 - screenshots.length }).map((_, index) => (
                        <div
                            key={index}
                            className="relative w-20 h-20 border-2 border-dashed border-gray-600 rounded-lg flex justify-center items-center cursor-pointer transform hover:scale-105 transition-transform duration-200"
                            onClick={() => handleAddImageClick(screenshots.length + index)}
                        >
                            <span className="text-gray-400">+</span>
                            <input
                                type="file"
                                accept="image/*"
                                id={`file-input-${screenshots.length + index}`}
                                className="hidden"
                                onChange={(e) => handleFileSelect(e)}
                            />
                        </div>
                    ))}
            </div>

            <div className="flex justify-center items-center w-full h-full">
                <div className="relative flex flex-col items-center justify-center w-full h-full">
                    <div
                        {...getRootProps()}
                        className="w-full h-[365px] flex flex-col items-center justify-center border-2 border-dashed border-gray-600 rounded-xl cursor-pointer transform transition-transform duration-300"
                    >
                        <input {...getInputProps()} className="hidden" />

                        {loading ? (
                            <div className="flex justify-center items-center w-full h-full">
                                <svg aria-hidden="true" role="status" className="inline w-12 h-12 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#6C5FFB" />
                                </svg>
                            </div>
                        ) : screenshots.length > 0 && selectedImage ? (
                            <img
                                src={typeof selectedImage?.file === "string" ? selectedImage.file : ""}
                                alt="Preview"
                                className="w-full h-full object-cover rounded-xl p-0.5"
                            />
                        ) : (
                            <div className="text-center text-gray-400 flex items-center justify-between flex-col h-full w-full">
                                <div className="flex items-center justify-center flex-col h-full">
                                    <File className="h-12 w-12 mb-4 text-gray-400" />
                                    <p className="text-lg font-medium">Arraste uma imagem aqui</p>
                                    <p className="text-sm mt-2">ou clique para selecionar</p>
                                    <p className="text-xs mt-4 bg-primary-200 px-2 py-1 rounded-lg text-white border-2 border-gray-300">
                                        Formatos aceitos: <span className="font-medium">JPEG, PNG, GIF</span>
                                    </p>
                                </div>
                                <p className="text-sm text-gray-600 h-8 pb-4">
                                    Tamanho máximo do arquivo: <span className="font-medium">25MB</span>
                                </p>
                            </div>
                        )}
                    </div>

                </div>
            </div>
        </div>

    )
}

export default ScreenshotUpload;