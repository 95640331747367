import React, { useEffect, useState } from "react";
import Request from "@assets/Table/Check.svg";
import TabSelector from "./table-selector";
import DataTable from "./generic-table";
import Going from "@assets/Table/Going.svg";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@material-tailwind/react";
import { IRequestResponse } from "types/services/IRequestResponse";
import api from "@services/client/api";
import Dropdown from "./(dropdown)";
import { IRequestDTO } from "@components/Chat/types/IRequest";
import ApprovePopup from "./(popups)/approve";
import handleCopy from "@utils/handlers/copy";
import CancelPopup from "./(popups)/cancel";
import RefundPopup from "./(popups)/refund";
import RestoreServicePopup from "./(popups)/back_to_available";
import PayPopup from "./(popups)/pay";

interface RequestFinishedAdmProps {
  token: string;
}

const TABLE_HEADS = {
  available: [
    { label: "ID", type: "custom-id" as const, key: "relative_id" },
    { label: "Game", type: "image" as const, key: "game.image" },
    { label: "Server", type: "text" as const, key: "server.value" },
    { label: "Boost Type", type: "text" as const, key: "details.boostType" },
    { label: "Description", type: "text" as const, key: "details.description" },
    { label: "Actions", type: "action" as const },
  ],
  booster_in_progress: [
    { label: "ID", type: "custom-id" as const, key: "relative_id" },
    { label: "Game", type: "image" as const, key: "game.image" },
    { label: "Booster", type: "booster" as const, key: "booster.champion_name" },
    { label: "Client", type: "text" as const, key: "client.nick_user" },
    { label: "Boost Type", type: "text" as const, key: "details.boostType" },
    { label: "Description", type: "text" as const, key: "details.description" },
    { label: "Actions", type: "action" as const },
  ],
  booster_abandoned: [
    { label: "ID", type: "custom-id" as const, key: "relative_id" },
    { label: "Game", type: "image" as const, key: "game.image" },
    { label: "Booster", type: "booster" as const, key: "booster.champion_name" },
    { label: "Client", type: "text" as const, key: "client.nick_user" },
    { label: "Boost Type", type: "text" as const, key: "details.boostType" },
    { label: "Description", type: "text" as const, key: "details.description" },
    { label: "Justification", type: "justification" as const, key: "operations" },
    { label: "Actions", type: "action" as const },
  ],
  booster_finished: [
    { label: "ID", type: "custom-id" as const, key: "relative_id" },
    { label: "Booster", type: "booster" as const, key: "booster.champion_name" },
    { label: "Client", type: "text" as const, key: "client.nick_user" },
    { label: "Value", type: "money" as const, key: "price" },
    { label: "Boost Type", type: "text" as const, key: "details.boostType" },
    { label: "Description", type: "text" as const, key: "details.description" },
    { label: "Actions", type: "action" as const },
  ],
  approved: [
    { label: "ID", type: "custom-id" as const, key: "relative_id" },
    { label: "Booster", type: "booster" as const, key: "booster.champion_name" },
    { label: "Client", type: "text" as const, key: "client.nick_user" },
    { label: "Value", type: "money" as const, key: "price" },
    { label: "Boost Type", type: "text" as const, key: "details.boostType" },
    { label: "Description", type: "text" as const, key: "details.description" },
    { label: "Actions", type: "action" as const },
  ],
  completed: [
    { label: "ID", type: "custom-id" as const, key: "relative_id" },
    { label: "Booster", type: "boosters" as const, key: "operations" },
    { label: "Client", type: "text" as const, key: "client.nick_user" },
    { label: "Value", type: "money" as const, key: "price" },
    { label: "Boost Type", type: "text" as const, key: "details.boostType" },
    { label: "Description", type: "text" as const, key: "details.description" },
    { label: "Actions", type: "action" as const },
  ],
  refund: [
    { label: "ID", type: "custom-id" as const, key: "relative_id" },
    { label: "Game", type: "image" as const, key: "game.image" },
    { label: "Boost Type", type: "text" as const, key: "details.boostType" },
    { label: "Description", type: "text" as const, key: "details.description" },
    { label: "Client", type: "text" as const, key: "client.nick_user" },
    { label: "Phone", type: "text" as const, key: "client.telephone" },
    { label: "Discord", type: "text" as const, key: "client.discord" },
    { label: "Actions", type: "action" as const },
  ],
};

type TableHeadsKey = keyof typeof TABLE_HEADS;

const RequestFinishedAdm: React.FC<RequestFinishedAdmProps> = ({ token }) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState(0);
  const [data, setData] = useState<Record<string, IRequestResponse[]>>({});
  const [openDropdowns, setOpenDropdowns] = useState<Record<string, boolean>>({});
  const [selectedOrder, setSelectedOrder] = useState<IRequestDTO | null>(null);
  const [selectedAction, setSelectedAction] = useState<string | null>(null);

  const toggleDropdown = (id: string) => {
    setOpenDropdowns((prev) => {
      const newState = { ...prev, [id]: !prev[id] };

      Object.keys(newState).forEach((key) => {
        if (key !== id) newState[key] = false;
      });

      return newState;
    });
  };

  const fetchData = async () => {
    const statuses = [
      "available",
      "booster_in_progress",
      "booster_abandoned",
      "booster_finished",
      "approved",
      "completed",
      "refund",
    ];

    try {
      const dataResponses = await Promise.all(
        statuses.map((status) =>
          api.get(`/order/status/${status}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then((response) => ({
            status,
            data: response.data,
          }))
        )
      );

      const newData: Record<string, IRequestResponse[]> = {};
      dataResponses.forEach(({ status, data }) => {
        newData[status] = data;
      });

      setData(newData);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };

  useEffect(() => {
    fetchData(); 
  }, [token]); 

  const tabs = [
    { title: t("admin.inqueue"), status: "available", head: TABLE_HEADS.available },
    { title: t("table.currentTitle"), status: "booster_in_progress", head: TABLE_HEADS.booster_in_progress },
    { title: t("Abandoned"), status: "booster_abandoned", head: TABLE_HEADS.booster_abandoned },
    { title: t("admin.titleapproval"), status: "booster_finished", head: TABLE_HEADS.booster_finished },
    { title: t("admin.titlepayment"), status: "approved", head: TABLE_HEADS.approved },
    { title: t("table.finalized"), status: "completed", head: TABLE_HEADS.completed },
    { title: t("admin.titlerefunded"), status: "refund", head: TABLE_HEADS.refund },
  ];

  const moveOrderToTab = async (orderId: string, newTab: string) => {
    try {
      const response = await api.get(`/order/${orderId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      const updatedOrder: any = response.data;  
  
      setData((prevData) => {
        const updatedData = { ...prevData };
  
        let currentTab = null;
        let orderToMove: any = null; 

        Object.keys(updatedData).forEach((tab) => {
          const tabData = updatedData[tab];
          const foundOrder = tabData.find((order) => order.id === orderId);
          if (foundOrder) {
            currentTab = tab;
            orderToMove = foundOrder;
          }
        });
  
        if (orderToMove && currentTab) {
          updatedData[currentTab] = updatedData[currentTab].filter(
            (order) => order.id !== orderId
          );

          if (orderToMove && updatedOrder && typeof orderToMove === 'object' && typeof updatedOrder === 'object') {
            updatedData[newTab] = updatedData[newTab] || [];
            updatedData[newTab].push({ ...orderToMove, ...updatedOrder });
          } else {
            console.error("Dados inválidos para a ordem ou informações atualizadas.");
          }
  
          return updatedData;
        }
  
        return prevData;
      });
    } catch (error) {
      console.error("Erro ao buscar e atualizar os dados do serviço:", error);
    }
  };
  


  const handleTabChange = (index: number) => {
    setCurrentTab(index);
  };

  const handleActionSelected = (action: string, order: IRequestDTO) => {
    setSelectedOrder(order);
    if (action === "copy_id") {
      handleCopy(order.id);
    }
    if (action === "view_info") {
      window.location.href = `/dashboard/chat/${order.id}`;
    }
    if (action === "approve_order") {
      setSelectedAction("approve_order");
    }
    if (action === "cancel_service") {
      setSelectedAction("cancel_service");
    }
    if (action === "refund") {
      setSelectedAction("refund");
    }
    if (action === "return_to_available") {
      setSelectedAction("return_to_available");
    }
    if (action === "pay_service") {
      setSelectedAction("pay_service");
    }
    toggleDropdown(order.id);
  };

  const closePopup = () => {
    setSelectedAction(null);
    setSelectedOrder(null);
  };

  return (
    <div className="relative transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5] dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-2xl p-4 border border-gray-100 dark:border-secondary-500 mt-4">
      <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base flex items-center gap-2">
        <img src={Request} alt={Request} />
        {t("admin.requests")}
      </h2>
      <div className="hidden sm:grid">
        <TabSelector
          tabs={tabs}
          currentTab={currentTab}
          handleTabChange={handleTabChange}
        />
        <div className="tab-content bg-white-100 dark:bg-blue-50 rounded-2xl p-4 mt-4">
          <DataTable
            head={tabs[currentTab].head}
            rows={data[tabs[currentTab].status] || []} 
            recordsLimit={15}
            actions={(row: any) => (
              <div className="flex flex-row gap-2 items-center justify-center">
                <button
                  className="p-2 bg-blue-700 rounded-md"
                  onClick={() => window.location.href = `/dashboard/chat/${row.id}`}
                  aria-label="Ir para o chat"
                >
                  <img src={Going} alt="Chat" width={18} />
                </button>
                <Tooltip
                  interactive={true}
                  className="bg-blue-50 border-2 border-gray-900"
                  open={openDropdowns[row.id]}
                  content={<Dropdown
                    row={row}
                    onRowAction={handleActionSelected} moveOrderToTab={moveOrderToTab}/>
                  }
                >
                  <button
                    onClick={() => toggleDropdown(row.id)}
                    className="inline-flex items-center text-sm font-medium text-center text-white rounded-lg"
                    type="button"
                  >
                    <svg
                      className="w-5 h-5"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 4 15"
                    >
                      <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                    </svg>
                  </button>
                </Tooltip>
              </div>
            )}
          />
        </div>
        {selectedAction === "approve_order" && selectedOrder && (
          <ApprovePopup
            row_order={selectedOrder}
            onClose={closePopup}
            moveOrderToTab={moveOrderToTab}
          />
        )}

        {selectedAction === "cancel_service" && selectedOrder && (
          <CancelPopup
            onClose={closePopup}
            orderId={selectedOrder.id || ''}
            moveOrderToTab={moveOrderToTab}
          />
        )}

        {selectedAction === "refund" && selectedOrder && (
          <RefundPopup
            onClose={closePopup}
            row_order={selectedOrder}
            moveOrderToTab={moveOrderToTab}
          />
        )}

        {selectedAction === "return_to_available" && selectedOrder && (
          <RestoreServicePopup
            onClose={closePopup}
            row_order={selectedOrder}
            moveOrderToTab={moveOrderToTab}
          />
        )}

        {selectedAction === "pay_service" && selectedOrder && (
          <PayPopup
            onClose={closePopup}
            row_order={selectedOrder} 
            moveOrderToTab={moveOrderToTab} />
        )}
      </div>
    </div>
  );
};

export default RequestFinishedAdm;
