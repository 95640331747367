import React from 'react';
import { Tooltip } from '@material-tailwind/react';
import { IOperation } from '@components/Chat/types/IRequest';

interface BoosterHistoryProps {
  operations: IOperation[];
}

const formatDate = (dateString: string) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true,
  };
  const date = new Date(dateString);
  return date.toLocaleDateString('pt-BR', options);
};

const BoosterHistory: React.FC<BoosterHistoryProps> = ({ operations }) => {
  const currentBooster = operations.find(op => op.current === true);
  const boosterHistory = operations.filter(op => op.current === false);

  if (!currentBooster) {
    return <div>Não há boosters para esse serviço.</div>;
  }

  return (
    <div className="relative select-none">
      {boosterHistory.length > 0 ? (
        <div className="cursor-pointer">
          <Tooltip
            className="bg-transparent"
            placement={"bottom-start"}
            interactive={true}
            content={
              <div className="bg-blue-50 bg-opacity-80 backdrop-blur-sm border-2 border-gray-900 text-white text-sm rounded-lg p-4 w-64 shadow-lg">
                <h3 className="font-semibold mb-2 text-lg">Histórico de Boosters</h3>
                <ul className="space-y-3">
                  {boosterHistory.map((operation, index) => (
                    <li key={index} className="flex items-center gap-3 hover:bg-gray-800 p-2 rounded-md transition duration-200">
                      <img
                        src={operation.booster.champion.image}
                        alt={operation.booster.champion.name}
                        className="w-10 h-10 rounded-full"
                      />
                      <div>
                        <span className="text-base">{operation.booster.champion.name}</span>
                        <div className="text-xs text-gray-400">
                          {formatDate(operation.created_at.toString())}
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            }
          >
            <div className="flex items-center gap-3 bg-indigo-600 px-1.5 py-0.5 rounded-full border-2 border-gray-300">
              <img
                src={currentBooster.booster.champion.image}
                alt={currentBooster.booster.champion.name}
                className="w-10 h-10 rounded-full"
              />
              <span className="font-bold text-lg">{currentBooster.booster.champion.name}</span>
            </div>
          </Tooltip>
        </div>
      ) : (
        <div className="flex items-center gap-3 bg-indigo-600 px-1.5 py-0.5 rounded-full border-2 border-gray-300">
          <img
            src={currentBooster.booster.champion.image}
            alt={currentBooster.booster.champion.name}
            className="w-10 h-10 rounded-full"
          />
          <span className="font-bold text-lg">{currentBooster.booster.champion.name}</span>
        </div>
      )}
    </div>
  );
};

export default BoosterHistory;
