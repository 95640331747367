import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import api from '@services/client/api';
import { useAuth } from '@context/AuthContext';

interface ConfirmDeletePopupProps {
    onClose: () => void;
    orderId: string;
    moveOrderToTab: (orderId: string, newTab: string) => void;
}

const CancelPopup: React.FC<ConfirmDeletePopupProps> = ({ onClose, orderId, moveOrderToTab }) => {
    const { token } = useAuth();

    const handleDelete = async () => {
        try {
            const response = await api.post(
                '/order/cancel',
                { order_id: orderId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            toast.success(response.data.message || 'Pedido cancelado com sucesso!');
            moveOrderToTab(orderId, "canceled")
            onClose();
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || 'Erro ao tentar cancelar o pedido.';
            toast.error(errorMessage);
        }
    };

    return (
        <div className="fixed inset-0 bg-blue-50 bg-opacity-80 flex justify-center items-center z-50 transition-opacity duration-300">
            <div className="bg-blue-50 border-2 border-gray-900 rounded-lg shadow-lg p-6 w-2/4">
                <h2 className="text-lg font-bold text-indigo-600 mb-4">
                    Confirmar Cancelamento
                </h2>
                <p className="text-gray-300 mb-6">
                    Tem certeza de que deseja cancelar o pedido?
                </p>
                <div className="flex justify-end space-x-4">
                    <button
                        onClick={onClose}
                        className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                    >
                        Não
                    </button>
                    <button
                        onClick={handleDelete}
                        className="px-4 py-2 bg-indigo-600 text-white rounded hover:bg-indigo-700"
                    >
                        Sim, cancelar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CancelPopup;
