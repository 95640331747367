import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Booster, IOperation, IRequestDTO } from '@components/Chat/types/IRequest';
import { useAuth } from '@context/AuthContext';
import Close from "@assets/Buy/Close.svg";
import api from '@services/client/api';
import handleCopy from '@utils/handlers/copy';
import { useTranslation } from 'react-i18next';

type BoosterProps = {
    booster: Booster;
    operation: IOperation;
    amount: number;
};

const BoosterDetails: React.FC<BoosterProps> = ({ operation, booster, amount }) => {
    const { t } = useTranslation();

    return (
        <div
            className={`bg-blue-20 w-full flex h-full relative flex-col justify-between gap-6 items-start rounded-xl p-4 border-2 ${operation.current ? 'border-gray-900' : 'border-gray-900'
                } shadow-lg`}
        >
            <div className='w-full h-full flex flex-col'>
                <div className="flex items-center gap-4">
                    <div className="relative w-16 h-16 border-2 border-indigo-800 rounded-full">
                        <img
                            src={booster.champion.image}
                            alt={booster.champion.name}
                            className="w-full h-full object-cover rounded-full"
                        />
                    </div>
                    <div className="flex flex-col">
                        <h4 className="text-white font-semibold text-xl">
                            {booster?.champion.name}
                        </h4>
                    </div>
                </div>

                <div className="mt-6">
                    <div className="flex items-center justify-between mb-4">
                        <p className="text-sm text-gray-300">{booster.services_quantity}</p>
                        <p className="text-sm text-gray-300">{booster.level_remain}</p>
                    </div>
                    <div className="h-2 bg-gray-700 rounded-full">
                        <div
                            className="h-full bg-gradient-to-r from-indigo-500 via-blue-600 to-indigo-700 rounded-full"
                            style={{
                                width: `${booster?.services_quantity && booster?.level_remain
                                    ? (booster.services_quantity / booster.level_remain) * 100
                                    : 0
                                    }%`,
                            }}
                        ></div>
                    </div>
                    <div className="flex items-center justify-between mt-6">
                        <p className="text-gray-600 font-semibold text-xs">
                            {t('client.current')}: <span className="text-gray-200">{booster.booster_level}</span>
                        </p>
                        <p className="text-gray-600 font-semibold text-xs">
                            {t('client.next')}: <span className="text-gray-200">{booster.booster_next_level}</span>
                        </p>
                    </div>
                </div>

                <div className="m-4 h-[2px] w-full mx-auto bg-gray-700"></div>

                <div className="mt-4">
                    <div className="w-full grid grid-cols-2 gap-4">
                        <div>
                            <p className="text-gray-400 text-sm font-semibold">Comissão:</p>
                            <p className="text-white text-sm font-medium">
                                {booster.gain_percentage}%
                            </p>
                        </div>
                        <div onClick={() => handleCopy(booster.payment_key)}>
                            <p className="text-gray-400 text-sm font-semibold">Pix:</p>
                            <p className="text-white text-sm font-medium whitespace-nowrap w-26 overflow-hidden cursor-pointer text-ellipsis flex">
                                {booster.payment_key}
                            </p>
                        </div>
                        <div>
                            <p className="text-gray-400 text-sm font-semibold">Status:</p>
                            <p
                                className={`text-sm font-medium ${operation.type === 'completed'
                                    ? 'text-green-400'
                                    : operation.type === 'abandoned'
                                        ? 'text-red-400'
                                        : operation.type === 'replaced'
                                            ? 'text-yellow-400'
                                            : operation.type === 'in_progress'
                                                ? 'text-blue-400'
                                                : 'text-gray-400'
                                    }`}
                            >
                                {operation.type === 'completed'
                                    ? 'Finalizado'
                                    : operation.type === 'abandoned'
                                        ? 'Abandonado'
                                        : operation.type === 'replaced'
                                            ? 'Substituído'
                                            : operation.type === 'in_progress'
                                                ? 'Em andamento'
                                                : 'Indefinido'}
                            </p>
                        </div>

                        <div>
                            <p className="text-gray-400 text-sm font-semibold">Justificativa:</p>
                            <p className="text-gray-200 text-sm font-medium">
                                {operation.justification ? operation.justification : "Sem justificativa."}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

interface RestoreServicePopupProps {
    row_order: IRequestDTO;
    onClose: () => void;
    moveOrderToTab: (orderId: string, newTab: string) => void;
}

const RestoreServicePopup: React.FC<RestoreServicePopupProps> = ({ row_order, onClose, moveOrderToTab }) => {
    const { token } = useAuth();

    const [errorMessage, setErrorMessage] = useState<string>("");
    const [operationAmount, setOperationAmount] = useState<number>(0);
    const [serviceValue, setServiceValue] = useState<number>(0);
    const [order, setOrder] = useState<IRequestDTO | null>(null);

    useEffect(() => {
        const fetchOrder = async () => {
            if (!token) return;
            try {
                const response = await api.get(`/order/${row_order.id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setOrder(response.data);
            } catch (error) {
                console.error("Erro ao buscar a ordem:", error);
            }
        };

        fetchOrder();
    }, [row_order, token]);

    if (!row_order || !order) return null;

    const currentOperation = Array.isArray(order?.operations)
        ? order.operations.find((op) => op.current)
        : null;

    const validateValues = (boosterValue: number, serviceValue: number) => {
        const total = boosterValue + serviceValue;
        if (total > order.price) {
            setErrorMessage("A soma do valor do booster e do serviço não pode exceder o preço total do serviço.");
            return false;
        }
        setErrorMessage("");
        return true;
    };

    const handleRestoreService = async () => {
        if (serviceValue <= 0) {
            setErrorMessage("Os valores devem ser maiores que zero.");
            return;
        }

        if (!validateValues(operationAmount, serviceValue)) return;

        const requestBody = {
            order_id: order.id,
            boosterValue: operationAmount,
            value: serviceValue,
        };

        try {
            const response = await api.post(
                `/order/return`,
                requestBody,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            toast.success(response.data.message || "Serviço restaurado com sucesso!");
            moveOrderToTab(order.id, "available")
            onClose();
        } catch (error: any) {
            const errorMessage =
                error.response?.data?.message || "Erro ao processar a restauração do serviço.";
            toast.error(errorMessage);
        }
    };

    const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value);
        if (event.target.value === "") {
            setOperationAmount(0);
        } else if (!isNaN(value) && value > 0) {
            if (validateValues(value, serviceValue)) {
                setOperationAmount(value);
            }
        } else {
            setErrorMessage("O valor deve ser um número positivo.");
        }
    };

    const handleServiceValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value);
        if (event.target.value === "") {
            setServiceValue(0);
        } else if (!isNaN(value) && value > 0) {
            if (validateValues(operationAmount, value)) {
                setServiceValue(value);
            }
        } else {
            setErrorMessage("O valor do serviço deve ser um número positivo.");
        }
    };

    return (
        <div className="fixed inset-0 bg-blue-50 bg-opacity-80 flex justify-center items-center z-50 transition-opacity duration-300">
            <div className="bg-blue-50 px-6 py-4 gap-4 flex flex-col items-center rounded-xl border-2 border-gray-700 shadow-lg w-1/3 md:w-3/4 lg:w-1/2 overflow-hidden relative">
                <div className="w-full flex justify-between items-center">
                    <h2 className="text-gray-200 text-lg font-bold">Pedido: #{order.relative_id}</h2>
                    <button onClick={onClose}>
                        <img src={Close} alt="Fechar" width={20} height={20} />
                    </button>
                </div>

                {currentOperation && (
                    <div className="w-full flex flex-col items-center gap-4">
                        <div className="w-full flex flex-row items-start gap-4 justify-between bg-primary-20 p-4 rounded-xl">
                            <BoosterDetails operation={currentOperation} booster={currentOperation.booster} amount={operationAmount} />
                        </div>
                    </div>
                )}

                {errorMessage && (
                    <div className="text-red-500 text-sm font-semibold mt-2">{errorMessage}</div>
                )}

                <div className="border border-gray-900 w-full bg-blue-50 rounded-md py-8 px-10 select-none">
                    <h3 className="text-lg text-indigo-600 font-semibold mb-4">Informações do Serviço</h3>
                    <div className="flex flex-row items-start justify-between">
                    <div className="flex flex-col gap-1">
                            <p className="text-sm font-semibold text-primary-200">Jogo:</p>
                            <img
                                src={order.game?.image || ""}
                                alt="Imagem do jogo"
                                className="rounded-md p-1.5 object-contain w-12 h-12"
                            />
                        </div>
                        <div className="flex flex-col gap-1">
                            <p className="text-sm font-semibold text-primary-200">Tipo de Boost:</p>
                            <p className="font-medium text-sm">{order.details.boostType}</p>
                        </div>
                        <div className="flex flex-col gap-1">
                            <p className="text-sm font-semibold text-primary-200">Descrição:</p>
                            <p className="font-medium text-sm">{order.details.description}</p>
                        </div>
                        <div className="flex flex-col gap-1">
                            <p className="text-sm font-semibold text-primary-200">Valor Total:</p>
                            <p className="font-medium text-sm">
                                R$ {(Number(order.price) - operationAmount).toFixed(2)}
                            </p>
                        </div>

                        <div className="flex flex-col gap-1">
                            <p className="text-sm font-semibold text-primary-200">Booster:</p>
                            <div className="flex flex-row gap-1 w-30">
                                <p className="font-medium text-sm">R$ {operationAmount.toFixed(2)}</p>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="border border-gray-900 w-full bg-blue-50 rounded-md py-8 px-10 select-none">
                    <h3 className="text-lg text-indigo-600 font-semibold mb-4">Configurações do Serviço</h3>
                    <div className="flex flex-row items-center justify-between">
                        <label className="text-gray-400 text-sm font-medium">Valor a pagar ao booster atual</label>
                        <input
                            type="number"
                            value={operationAmount || ""}
                            onChange={handleAmountChange}
                            className="p-3 border-2 rounded-lg border-indigo-600 bg-blue-500 text-white placeholder-gray-500 outline-none transition-all duration-200 ease-in-out"
                        />
                    </div>
                    <div className="flex flex-row items-center justify-between mt-4">
                        <label className="text-gray-400 text-sm font-medium">Valor do novo serviço</label>
                        <input
                            type="number"
                            value={serviceValue || ""}
                            onChange={handleServiceValueChange}
                            className="p-3 border-2 rounded-lg border-indigo-600 bg-blue-500 text-white placeholder-gray-500 outline-none transition-all duration-200 ease-in-out"
                        />
                    </div>
                </div>

                <div className="p-2 flex flex-row justify-end gap-3 w-full">
                    <button
                        onClick={onClose}
                        type="button"
                        className="focus:outline-none text-white bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-900"
                    >
                        Cancelar
                    </button>

                    <button
                        type="button"
                        onClick={handleRestoreService}
                        className="focus:outline-none text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-indigo-600 dark:hover:bg-indigo-700 dark:focus:ring-indigo-900"
                    >
                        Restaurar Serviço
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RestoreServicePopup;
