import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Close from "@assets/Buy/Close.svg";
import { IRequestDTO } from "@components/Chat/types/IRequest";

interface AbandonServicePopupProps {
  token: string;
  order: IRequestDTO;
  onClose: () => void;
  onAbandon: (token: string, data: { order_id: string, reason: string }) => void;
}

const AbandonServicePopup: React.FC<AbandonServicePopupProps> = ({
  token,
  order,
  onClose,
  onAbandon,
}) => {
  const [reason, setReason] = useState("");
  const { t } = useTranslation();

  const handleSubmit = () => {
    if (reason.trim()) {
      onAbandon(token, { order_id: order.id, reason });
    } else {
      alert("Por favor, insira um motivo para abandonar o serviço.");
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center z-50 transition-opacity duration-300">
      <div className="bg-blue-50 px-6 py-4 flex flex-col items-center gap-4 rounded-xl border-2 border-gray-700 shadow-2xl w-3/4 max-w-lg transition-all duration-300">
        <div className="w-full flex justify-between items-center">
          <h2 className="text-gray-200 text-lg font-bold">
            {t("Abandonar Serviço")} #{order.relative_id}
          </h2>
          <button onClick={onClose}>
            <img src={Close} alt="Fechar" width={20} height={20} />
          </button>
        </div>

        <div className="w-full flex flex-col gap-4">
          <label className="text-gray-400 text-sm font-medium">
            {t("Por favor, informe o motivo para abandonar o serviço:")}
          </label>
          <textarea
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            placeholder={t("Digite o motivo aqui...")}
            className="w-full h-24 border-2 border-gray-600 rounded-lg p-2 text-gray-200 bg-blue-50 resize-none focus:outline-none"
          ></textarea>
        </div>

        <div className="w-full flex justify-end gap-4">
          <button
            disabled={!reason.trim()}
            onClick={handleSubmit}
            className={`px-4 py-2 rounded-lg text-sm font-medium text-white transition-all 
                ${reason.trim() ? "bg-indigo-500 hover:bg-indigo-600" : "bg-gray-600 cursor-not-allowed"}`}>
            {t("Confirmar")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AbandonServicePopup;
