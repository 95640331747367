import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import api from "@services/client/api";
import DataTable from "@components/Dashboard/mainDashboard/Adm/requestFinished/generic-table";
import Bag from "@assets/Table/Check.svg";
import { IRequestDTO } from "@components/Chat/types/IRequest";
import Play from "@assets/Table/Play.svg";
import Check from "@assets/Table/HistoricTable.svg";
import IconAndamento from "@assets/Table/IconAndamento.svg";
import IconAvaliar from "@assets/Table/IconAvaliar.svg";
import IconMensagem from "@assets/Table/Message.svg";
import PopupInfoFeedback from "@components/Dashboard/mainDashboard/Adm/client/feedback/PopupInfoFeedback";
import { Link } from "react-router-dom";

interface AvailableClientProps {
    token: string;
}

const TABLE_HEADS = [
    { label: "ID", type: "custom-id" as const, key: "relative_id" },
    { label: "Game", type: "image" as const, key: "game.image" },
    { label: "Server", type: "text" as const, key: "server.value" },
    { label: "Boost Type", type: "text" as const, key: "details.boostType" },
    { label: "Description", type: "text" as const, key: "details.description" },
    { label: "Actions", type: "action" as const },
];

const AvailableClient: React.FC<AvailableClientProps> = ({ token }) => {
    const { t } = useTranslation();
    const [data, setData] = useState<IRequestDTO[]>([]);
    const [showPopup, setShowPopup] = useState(false);
    const [orderId, setOrderID] = useState("");

    const fetchData = async () => {
        try {
            const response = await api.get(`/order/client/available`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setData(response.data);
        } catch (error) {
            console.error("Erro ao buscar dados:", error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [token]);

    const handleActionClick = (row: { [key: string]: any }) => {

    };

    const handleButtonClick = (id: string) => {
        setOrderID(id);
        setShowPopup((prevState) => !prevState);
    };

    const renderStatus = (status: string, id: string) => {
        if (status === "booster_finished") {
            return (
                <span className="text-green-500 flex justify-center items-center gap-2">
                    Concluído
                    <button className="p-2" onClick={() => handleButtonClick(id)}>
                        <img src={IconAvaliar} alt="Avaliar" />
                    </button>
                </span>
            );
        } else if (status === "booster_in_progress") {
            return (
                <div className="bg-blue-1001 rounded-full w-auto inline-block">
                    <Link
                        to={`/dashboard/chat/${id}`}
                        className="text-gray-500 font-semibold flex justify-center items-center px-4 py-2 gap-2"
                    >
                        Em andamento
                        <img src={IconAndamento} alt="Andamento" />
                    </Link>
                </div>
            );
        } else if (status === "available") {
            return (
                <span className="text-gray-100 flex justify-center items-center gap-2">
                    Na fila
                    <Link to={`/dashboard/chat/${id}`} className="p-2">
                        <img src={IconMensagem} alt="Mensagem" />
                    </Link>
                </span>
            );
        }
        return null;
    };


    return (
        <div className="transition-colors duration-1000 bg-gradient-to-b from-[#7C8092] via-[#F5F5F5] to-[#F5F5F5] dark:from-[#02061B] dark:via-[#101230] dark:to-[#101230] rounded-2xl p-4 border border-gray-100 dark:border-secondary-500 mt-4">
            <div className="flex items-center justify-between">
            <h2 className="transition-colors duration-1000 text-blue-50 dark:text-gray-500 font-semibold text-base flex items-center gap-2">
          <img src={Check} alt={Check} />
          {t("table.serviceTitle")}
        </h2>
            </div>

            <div className="tab-content bg-white-100 dark:bg-blue-50 rounded-2xl p-4 mt-4">
                <DataTable
                    head={TABLE_HEADS}
                    rows={data}
                    recordsLimit={15}
                    actions={(row: { [key: string]: any }) => renderStatus(row.status, row.id)}
                />

            </div>

            {showPopup && <PopupInfoFeedback orderId={orderId} />}
        </div>
    );
};

export default AvailableClient;
